import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import PropTypes from 'prop-types';

import { operatorsDict } from '@og-pro/shared-config/customFormService/conditionalStatement';

import { useLoadCustomForm } from '../../../../../../lib/customFormService/useLoadCustomForm';
import { fieldNames, form } from '../constants';
import { useListCustomFields } from '../../../../../../lib/customFormService/useListCustomFields';

const CustomFormEditorContext = createContext();

const {
    REQUIRED_FIELDS,
    FIELDS_ORDER,
    CONDITIONAL_STATEMENTS,
    CREATE_NEW_CUSTOM_FORM,
    DELETE_CUSTOM_FORM,
} = fieldNames;

export const useCustomFormEditor = () => useContext(CustomFormEditorContext);

export const CustomFormEditorProvider = ({
    children,
    customFormId,
    isSectionSelected,
    setIsSectionSelected,
}) => {
    const createNewCustomForm = useSelector((state) =>
        formValueSelector(form)(state, CREATE_NEW_CUSTOM_FORM)
    );
    const deleteCustomForm = useSelector((state) =>
        formValueSelector(form)(state, DELETE_CUSTOM_FORM)
    );

    const dispatch = useDispatch();
    const { data: customFormData, isLoading: isCustomFormDataCallLoading } =
        useLoadCustomForm(customFormId);
    const isCustomFormLoading = customFormId && isCustomFormDataCallLoading;

    const [requiredFields, setRequiredFields] = useState([]);
    const [rootCustomFields, setRootCustomFields] = useState([]);
    const [showAddFieldForm, setShowAddFieldForm] = useState(false);
    const [focusedCustomFieldId, setFocusedCustomFieldId] = useState(null);

    const fieldsOrder = useMemo(() => {
        return rootCustomFields.flatMap((rootCustomField) => {
            return [
                rootCustomField.customFieldId,
                ...rootCustomField.conditionalFields.map(
                    (conditionalField) => conditionalField.show_custom_field_id
                ),
            ];
        });
    }, [rootCustomFields]);

    // Reset focusedCustomFieldId when the section is de-selected
    useEffect(() => {
        if (!isSectionSelected) {
            setFocusedCustomFieldId(null);
        }
    }, [isSectionSelected]);

    // Select custom field section when a field gets focus
    useEffect(() => {
        if (focusedCustomFieldId && !isSectionSelected) {
            setIsSectionSelected();
        }
    }, [focusedCustomFieldId, isSectionSelected, setIsSectionSelected]);

    useEffect(() => {
        if (!isCustomFormLoading && !!customFormData?.getCustomForm) {
            const conditionalStatements = customFormData.getCustomForm.conditionalStatements || [];
            const allConditionalFieldIds = conditionalStatements.map(
                (statement) => statement.show_custom_field_id
            );
            const rootCustomFieldsFromBackend = customFormData.getCustomForm.fieldsOrder
                .filter((customFieldId) => !allConditionalFieldIds.includes(customFieldId))
                .map((customFieldId) => {
                    const conditionalFields = conditionalStatements
                        .filter((statement) => statement.operand_custom_field_id === customFieldId)
                        .map((statement) => ({
                            conditionalStatementId: statement.id,
                            show_custom_field_id: statement.show_custom_field_id,
                            operand_custom_field_id: customFieldId,
                            operandValue: statement.operandValue,
                        }));
                    return { customFieldId, conditionalFields };
                });

            setRootCustomFields(rootCustomFieldsFromBackend);
            setRequiredFields(customFormData.getCustomForm.requiredFields);
            dispatch(change(form, CONDITIONAL_STATEMENTS, conditionalStatements));
        }
    }, [isCustomFormLoading, customFormData, dispatch]);

    const updateConditionalStatements = useCallback(() => {
        const conditionalStatements = rootCustomFields.flatMap((rootCustomField) =>
            rootCustomField.conditionalFields.map((conditionalField) => ({
                id: conditionalField.conditionalStatementId,
                show_custom_field_id: conditionalField.show_custom_field_id,
                operand_custom_field_id: rootCustomField.customFieldId,
                operator: operatorsDict.EQ,
                operandValue: conditionalField.operandValue,
            }))
        );
        dispatch(change(form, CONDITIONAL_STATEMENTS, conditionalStatements));
    }, [rootCustomFields, dispatch]);

    // These three below fields are managed via useState,
    // but they're also added to the redux-form store so they're sent when saving the request type.
    useEffect(() => {
        const hasCustomForm = !deleteCustomForm && (createNewCustomForm || !!customFormId);
        if (hasCustomForm) {
            dispatch(change(form, FIELDS_ORDER, fieldsOrder));
            dispatch(change(form, REQUIRED_FIELDS, requiredFields));
            updateConditionalStatements();
        }
    }, [
        dispatch,
        fieldsOrder,
        requiredFields,
        updateConditionalStatements,
        customFormId,
        createNewCustomForm,
        deleteCustomForm,
    ]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setFocusedCustomFieldId(null);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const removeField = useCallback((idToRemove) => {
        setRootCustomFields((prev) =>
            prev.filter((rootCustomField) => rootCustomField.customFieldId !== idToRemove)
        );
        setRequiredFields((prev) => prev.filter((customFieldId) => customFieldId !== idToRemove));
    }, []);

    const addField = useCallback(
        (idToAdd) => {
            if (idToAdd && !fieldsOrder.includes(idToAdd)) {
                setRootCustomFields((prev) => [
                    ...prev,
                    { customFieldId: idToAdd, conditionalFields: [] },
                ]);
            }
        },
        [fieldsOrder]
    );

    const setConditionalField = useCallback(
        (rootCustomFieldId, conditionalFieldIndex) => (idToSet) => {
            if (idToSet && !fieldsOrder.includes(idToSet)) {
                setRootCustomFields((prev) =>
                    prev.map((rootCustomField) => {
                        if (rootCustomField.customFieldId === rootCustomFieldId) {
                            const updatedConditionalFields = rootCustomField.conditionalFields.map(
                                (field, index) => {
                                    if (index === conditionalFieldIndex) {
                                        return { ...field, show_custom_field_id: idToSet };
                                    }
                                    return field;
                                }
                            );

                            return {
                                ...rootCustomField,
                                conditionalFields: updatedConditionalFields,
                            };
                        }
                        return rootCustomField;
                    })
                );
            }
            updateConditionalStatements();
        },
        [fieldsOrder, updateConditionalStatements]
    );

    const setConditionalValue = useCallback(
        (rootCustomFieldId, conditionalFieldIndex) => (value) => {
            setRootCustomFields((prev) =>
                prev.map((rootCustomField) =>
                    rootCustomField.customFieldId === rootCustomFieldId
                        ? {
                              ...rootCustomField,
                              conditionalFields: rootCustomField.conditionalFields.map(
                                  (field, index) =>
                                      index === conditionalFieldIndex
                                          ? { ...field, operandValue: value }
                                          : field
                              ),
                          }
                        : rootCustomField
                )
            );
            updateConditionalStatements();
        },
        [updateConditionalStatements]
    );

    const addConditionalField = useCallback((rootCustomFieldId) => {
        setRootCustomFields((prev) =>
            prev.map((rootCustomField) => {
                if (rootCustomField.customFieldId === rootCustomFieldId) {
                    return {
                        ...rootCustomField,
                        conditionalFields: [
                            ...rootCustomField.conditionalFields,
                            { show_custom_field_id: null, operandValue: '' },
                        ],
                    };
                }
                return rootCustomField;
            })
        );
    }, []);

    const removeConditionalField = useCallback(
        (rootCustomFieldId, idToRemove) => {
            setRootCustomFields((prev) =>
                prev.map((rootCustomField) =>
                    rootCustomField.customFieldId === rootCustomFieldId
                        ? {
                              ...rootCustomField,
                              conditionalFields: rootCustomField.conditionalFields.filter(
                                  (field) => field.show_custom_field_id !== idToRemove
                              ),
                          }
                        : rootCustomField
                )
            );
            setRequiredFields((prev) =>
                prev.filter((customFieldId) => customFieldId !== idToRemove)
            );
            updateConditionalStatements();
        },
        [updateConditionalStatements]
    );

    const toggleRequiredField = useCallback((customFieldId) => {
        setRequiredFields((prev) =>
            prev.includes(customFieldId)
                ? prev.filter((id) => id !== customFieldId)
                : [...prev, customFieldId]
        );
    }, []);

    const isRequired = useCallback(
        (customFieldId) => requiredFields.includes(customFieldId),
        [requiredFields]
    );

    const { data: listCustomFieldsData } = useListCustomFields();
    const customFieldDefinitions = listCustomFieldsData.getAllCustomFields;

    const getCustomFieldDefinition = useCallback(
        (id) => customFieldDefinitions.find((x) => x.id === id),
        [customFieldDefinitions]
    );

    const value = useMemo(
        () => ({
            customFormId,
            isCustomFormLoading,
            rootCustomFields,
            fieldsOrder,
            isRequired,
            getCustomFieldDefinition,
            removeField,
            addField,
            setConditionalField,
            setConditionalValue,
            addConditionalField,
            removeConditionalField,
            toggleRequiredField,
            showAddFieldForm,
            setShowAddFieldForm,
            focusedCustomFieldId,
            setFocusedCustomFieldId,
        }),
        [
            customFormId,
            isCustomFormLoading,
            rootCustomFields,
            fieldsOrder,
            isRequired,
            getCustomFieldDefinition,
            removeField,
            addField,
            setConditionalField,
            setConditionalValue,
            addConditionalField,
            removeConditionalField,
            toggleRequiredField,
            showAddFieldForm,
            setShowAddFieldForm,
            focusedCustomFieldId,
            setFocusedCustomFieldId,
        ]
    );

    return (
        <CustomFormEditorContext.Provider value={value}>
            {children}
        </CustomFormEditorContext.Provider>
    );
};

CustomFormEditorProvider.propTypes = {
    children: PropTypes.node.isRequired,
    // Could be null
    customFormId: PropTypes.number,
    isSectionSelected: PropTypes.bool.isRequired,
    setIsSectionSelected: PropTypes.func.isRequired,
};
