import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Panel } from 'react-bootstrap';
import { Box, Button as MuiButton } from '@og-pro/ui';
import { addendumStatusTypes, getAddendumText } from '@og-pro/shared-config/addendums';

import { AddendumConfirmTable } from './AddendumConfirmTable';
import { AddendumDiffModal } from './AddendumDiffModal';
import { AddendumForm } from '../../Forms/AddendumForm';
import { Button } from '../../Button';
import { Collapse } from '../../Collapse';
import { FileUploadAttachment } from '../../FileUploadAttachment';
import { HtmlContent } from '../../HtmlContent/HtmlContent';
import { UserProfilePicture } from '../../UserProfilePicture/UserProfilePicture';
import { form as addendumFormName } from '../../Forms/AddendumForm/constants';
import { AddendumAI } from './AddendumAI';

const { RELEASED } = addendumStatusTypes;

export class AddendumItem extends PureComponent {
    static propTypes = {
        addendum: PropTypes.shape({
            attachments: PropTypes.array.isRequired,
            deleteError: PropTypes.string,
            deleting: PropTypes.bool,
            description: PropTypes.string,
            diff: PropTypes.object,
            id: PropTypes.number.isRequired,
            project_id: PropTypes.number.isRequired,
            releasedAt: PropTypes.string,
            status: PropTypes.string.isRequired,
            titleDisplay: PropTypes.string.isRequired,
            updateError: PropTypes.string,
            updating: PropTypes.bool,
            user: PropTypes.object,
        }).isRequired,
        canEdit: PropTypes.bool,
        classNameSelector: PropTypes.string.isRequired,
        deleteHandler: PropTypes.func,
        downloadAttachments: PropTypes.func,
        hasAutoAddenda: PropTypes.bool,
        isGovernmentView: PropTypes.bool,
        isNotice: PropTypes.bool,
        isSubscribed: PropTypes.bool,
        loadingProposals: PropTypes.bool,
        proposals: PropTypes.array,
        project: PropTypes.object.isRequired,
        releaseHandler: PropTypes.func,
        showDiffModalOnInitialization: PropTypes.bool,
        updateHandler: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            showConfirms: false,
            showDiffModal: props.showDiffModalOnInitialization || false,
            showForm: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get addendumText() {
        return getAddendumText(this.props.addendum);
    }

    closeForm = () => this.setState({ showForm: false });

    showForm = () => this.setState({ showForm: true });

    deleteHandler = () => {
        const { addendum, deleteHandler } = this.props;

        deleteHandler(addendum);
    };

    releaseHandler = () => {
        const { addendum, releaseHandler } = this.props;

        releaseHandler(addendum);
    };

    updateHandler = (data) => {
        const { addendum, updateHandler } = this.props;

        updateHandler(addendum, data, this.closeForm);
    };

    renderTitle = ({ disabled, isEditingReleased, isReleased, showEditMenu }) => {
        const {
            addendum: { releasedAt, titleDisplay },
            canEdit,
        } = this.props;

        if (isEditingReleased) {
            return (
                <h4 className={this.styles.title}>
                    {titleDisplay}
                    <div className={`text-danger ${this.styles.titleInfo}`}>
                        <i className="fa fa-exclamation-triangle" />
                        &nbsp; Editing {this.addendumText.lower} that has already been publicly
                        released
                    </div>
                </h4>
            );
        }

        if (isReleased) {
            return (
                <h4 className={this.styles.title}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            {titleDisplay}
                            {canEdit && (
                                <Button
                                    bsSize="sm"
                                    bsStyle="link"
                                    className={this.styles.editButton}
                                    disabled={disabled}
                                    onClick={this.showForm}
                                    qaTag="addendumItem-edit"
                                    zeroPadding
                                >
                                    <i className="fa fa-pencil" /> Edit
                                </Button>
                            )}
                        </div>
                        <div className={`col-xs-12 col-sm-6 text-muted ${this.styles.releasedAt}`}>
                            {moment(releasedAt).format('lll')}
                        </div>
                    </div>
                </h4>
            );
        }

        return (
            <h4 className={this.styles.title}>
                {showEditMenu && (
                    <div className="pull-right">
                        {canEdit && (
                            <Button
                                bsSize="sm"
                                bsStyle="link"
                                disabled={disabled}
                                onClick={this.showForm}
                                qaTag="addendumItem-edit"
                            >
                                <i className="fa fa-pencil" /> Edit
                            </Button>
                        )}
                        &nbsp;
                        <Button
                            bsSize="sm"
                            bsStyle="primary"
                            disabled={!canEdit || disabled}
                            onClick={this.releaseHandler}
                            qaTag="addendumItem-release"
                            tooltip={
                                canEdit
                                    ? undefined
                                    : `Only sourcing editors and admins can release ${this.addendumText.lowerPlural}`
                            }
                        >
                            <i className="fa fa-send" /> Release
                        </Button>
                    </div>
                )}
                {titleDisplay}
                <div className={`text-warning ${this.styles.titleInfo}`}>
                    <i className="fa fa-exclamation-triangle" /> Not publicly released
                </div>
                <div className={`text-muted ${this.styles.titleInfo}`}>
                    <em>{this.addendumText.upper} number will be issued upon release</em>
                </div>
            </h4>
        );
    };

    render() {
        const {
            addendum,
            addendum: {
                attachments,
                deleteError,
                deleting,
                description,
                diff,
                id,
                project_id: projectId,
                releasedAt,
                status,
                updateError,
                updating,
                user,
            },
            canEdit,
            classNameSelector,
            downloadAttachments,
            hasAutoAddenda,
            isGovernmentView,
            isNotice,
            isSubscribed,
            loadingProposals,
            proposals,
            project,
        } = this.props;

        const { showConfirms, showDiffModal, showForm } = this.state;

        const isReleased = status === RELEASED;
        const disabled = deleting || updating;
        const TitleComponent = this.renderTitle;

        if (showForm) {
            return (
                <div>
                    <TitleComponent disabled={disabled} isEditingReleased={isReleased} />
                    <Panel>
                        <Panel.Body>
                            <AddendumForm
                                closeForm={this.closeForm}
                                deleteHandler={this.deleteHandler}
                                disabled={disabled}
                                form={`${addendumFormName}${id}`}
                                hasAutoAddenda={hasAutoAddenda}
                                initialValues={addendum}
                                isNotice={isNotice}
                                isReleased={isReleased}
                                onSubmit={this.updateHandler}
                                projectId={projectId}
                                serverError={updateError || deleteError}
                            />
                        </Panel.Body>
                    </Panel>
                </div>
            );
        }

        const proposalsData = (proposals || []).map((proposal) => {
            return {
                ...proposal,
                addendumConfirm: proposal.addendumConfirms.find((addendumConfirm) => {
                    return addendumConfirm && addendumConfirm.addendum_id === addendum.id;
                }),
            };
        });

        return (
            <div className={this.styles.addendumItem} id={`${classNameSelector}-${id}`}>
                <TitleComponent disabled={disabled} isReleased={isReleased} showEditMenu />
                <div className={this.styles.body}>
                    {description && <HtmlContent content={description} />}
                    {diff && (
                        <Box sx={{ alignItems: 'center', display: 'flex', my: 2 }}>
                            <Box sx={{ mr: 1 }}>
                                <MuiButton
                                    color="success"
                                    onClick={() => this.setState({ showDiffModal: true })}
                                    qaTag="addendumItem-seeWhatChanged"
                                    size="small"
                                    variant="outlined"
                                >
                                    See What Changed
                                </MuiButton>
                            </Box>
                            <Box>
                                {canEdit && (
                                    <AddendumAI
                                        addendum={addendum}
                                        updateHandler={this.updateHandler}
                                    />
                                )}
                            </Box>
                        </Box>
                    )}
                    {attachments
                        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                        .map((attachment) => (
                            <FileUploadAttachment
                                attachment={attachment}
                                disabled={disabled}
                                downloadAttachments={downloadAttachments}
                                isGovernmentView={isGovernmentView}
                                isSubscribed={isSubscribed}
                                key={attachment.id}
                                project={project}
                                useListItemStyle
                            />
                        ))}
                    {isGovernmentView && user && (
                        <div>
                            <UserProfilePicture horizontal user={user} />
                            &nbsp;
                            {user.displayName}
                            &nbsp;
                            <small className="text-muted" title={moment(releasedAt).format('lll')}>
                                <em>{moment(releasedAt).fromNow()}</em>
                            </small>
                        </div>
                    )}
                    {isGovernmentView && !isNotice && !loadingProposals && (
                        <div className={this.styles.addendaConfirm}>
                            <Button
                                bsStyle="link"
                                onClick={() => {
                                    return this.setState((prevState) => {
                                        return { showConfirms: !prevState.showConfirms };
                                    });
                                }}
                                qaTag="addendumItem-acknowledged"
                                zeroPadding
                            >
                                <i
                                    className={`fa fa-fw fa-caret-${
                                        showConfirms ? 'down' : 'right'
                                    }`}
                                />
                                Acknowledged by&nbsp;
                                {proposalsData.filter((prop) => prop.addendumConfirm).length}{' '}
                                of&nbsp;
                                {proposalsData.length} responses
                            </Button>
                            <Collapse in={showConfirms}>
                                <div className={this.styles.addendaConfirmTable}>
                                    <AddendumConfirmTable
                                        proposals={proposalsData}
                                        title={`${addendum.titleDisplay} Acknowledgements`}
                                    />
                                </div>
                            </Collapse>
                        </div>
                    )}
                </div>
                {showDiffModal && (
                    <AddendumDiffModal
                        addendum={addendum}
                        hideModal={() => this.setState({ showDiffModal: false })}
                        projectId={projectId}
                    />
                )}
            </div>
        );
    }
}
