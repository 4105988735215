import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class AiChartWidget extends PureComponent {
    static propTypes = {
        chartConfig: PropTypes.object.isRequired,
    };

    get noData() {
        return Object.keys(this.props.chartConfig).length === 0;
    }

    componentDidUpdate() {
        this.renderChart();
    }

    renderChart() {
        if (!this.noData) {
            const { chartConfig } = this.props;
            const { chart, ...configRest } = chartConfig;
            // eslint-disable-next-line new-cap
            return new Highcharts.chart({
                chart: {
                    type: chart.type,
                    renderTo: 'ai-chart-container',
                    borderWidth: 1,
                    borderColor: '#dce0e0',
                    style: {
                        fontWeight: 500,
                        fontSize: '18px',
                        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    },
                },
                legend: {
                    enabled: false,
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                        },
                    },
                },
                credits: {
                    enabled: false,
                },
                ...configRest,
            });
        }
    }

    render() {
        return <div id="ai-chart-container" />;
    }
}
