import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { reset, submit } from 'redux-form';
import { Box } from '@og-pro/ui';

import { projectTypesDict } from '@og-pro/shared-config/projects';

import { isUserAwardedVendor } from '@og-pro/shared-config/helpers';

import { Button } from '../Button';
import { Main } from '../Main';
import { OutlineButton } from '../OutlineButton';
import { ContractForm } from '../Forms/ContractForm';
import { ContractDetails } from '../ContractDetails';
import { form } from '../Forms/ContractForm/constants';
import { showConfirmationSimpleModal } from '../../actions/confirmation';
import { deleteContract, updateContract } from '../../actions/contracts';
import { showProjectCreateModal } from '../../actions/project/create/projectCreate';
import {
    getProjectJS,
    hasDocBuilderSubscription,
    isContractEditor,
} from '../../containers/GovApp/selectors';
import { getGovernmentJS } from '../../containers/PublicApp/selectors';
import { getContractJS, getUserJS, getUserOrganizationTimezone } from '../../containers/selectors';

const { CONTRACT } = projectTypesDict;

const mapStateToProps = (state) => {
    return {
        contract: getContractJS(state),
        deleteContractError: state.contracts.get('deleteContractError'),
        deletingContract: !!state.contracts.get('deletingContract'),
        hasDocBuilder: hasDocBuilderSubscription(state),
        updateContractError: state.contracts.get('updateContractError'),
        updatingContract: !!state.contracts.get('updatingContract'),
    };
};

const mapDispatchToProps = {
    deleteContract,
    resetForm: reset,
    showConfirmationSimpleModal,
    showProjectCreateModal,
    updateContract,
};

// @withRouter
// @connect
class ConnectedContract extends Component {
    static propTypes = {
        contract: PropTypes.shape({
            contact_id: PropTypes.number,
            department_id: PropTypes.number,
            id: PropTypes.number.isRequired,
            procurement_contact_id: PropTypes.number,
            project: PropTypes.object,
            status: PropTypes.string.isRequired,
        }).isRequired,
        deleteContract: PropTypes.func.isRequired,
        deleteContractError: PropTypes.string,
        deletingContract: PropTypes.bool,
        dispatch: PropTypes.func.isRequired, // comes from redux-form
        hasDocBuilder: PropTypes.bool.isRequired,
        isAwardedVendor: PropTypes.bool,
        isEditor: PropTypes.bool,
        isPublicView: PropTypes.bool,
        params: PropTypes.shape({
            projectId: PropTypes.string,
        }).isRequired,
        resetForm: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showProjectCreateModal: PropTypes.func.isRequired,
        timezone: PropTypes.string.isRequired,
        updateContractError: PropTypes.string,
        updatingContract: PropTypes.bool,
        updateContract: PropTypes.func.isRequired,
    };

    static defaultProps = {
        deletingContract: false,
        deleteContractError: undefined,
        isEditor: false,
        isPublicView: false,
        updatingContract: false,
        updateContractError: undefined,
    };

    constructor(props) {
        super(props);

        this.state = { editing: false };
    }

    handleCreateContractDocumentClick = () => {
        const { contract } = this.props;

        this.props.showProjectCreateModal({
            createData: {
                contact_id: contract.contact_id,
                contractId: contract.id,
                department_id: contract.department_id,
                procurement_contact_id: contract.procurement_contact_id,
            },
            isDocBuilder: true,
            steps: [5],
            type: CONTRACT,
        });
    };

    handleDeleteClick = () => {
        const {
            contract: { id: contractId },
            params: { projectId },
        } = this.props;

        this.props.showConfirmationSimpleModal(
            () => this.props.deleteContract(contractId, projectId),
            {
                btnText: 'Delete',
                text: 'Are you sure you want to delete this contract? This cannot be undone.',
            }
        );
    };

    handleEditClick = () => {
        this.setState((prevState) => {
            return {
                editing: !prevState.editing,
            };
        });
    };

    handleSubmit = (data) => {
        const {
            contract: { id: contractId },
            params: { projectId },
            resetForm,
        } = this.props;

        // TODO: this should become a form field with a project association selector
        if (projectId) {
            data.projectId = Number.parseInt(projectId, 10);
        }

        this.props.updateContract(contractId, data, () => {
            // On successful update, reset and disable form
            resetForm(form);
            this.setState({ editing: false });
        });
    };

    renderContractDocBuilder() {
        const { deletingContract, hasDocBuilder, updatingContract } = this.props;

        if (!hasDocBuilder) {
            return null;
        }

        return (
            <Box flex={1} textAlign="right">
                <Button
                    bsSize="sm"
                    bsStyle="primary"
                    disabled={deletingContract || updatingContract}
                    onClick={this.handleCreateContractDocumentClick}
                    qaTag="connectedContract-createContractDocument"
                >
                    <i className="fa fa-plus" /> Create Document
                </Button>
            </Box>
        );
    }

    render() {
        const {
            contract,
            deleteContractError,
            deletingContract,
            dispatch,
            isAwardedVendor,
            isEditor,
            isPublicView,
            timezone,
            updateContractError,
            updatingContract,
        } = this.props;

        const { editing } = this.state;

        return (
            <Main className="row">
                <div className="col-lg-offset-1 col-lg-10">
                    {isEditor && (
                        <Box display="flex" mb={2}>
                            <Box flex={1}>
                                <Button
                                    bsStyle="link"
                                    disabled={deletingContract || updatingContract}
                                    onClick={this.handleEditClick}
                                    qaTag="connectedContract-updatingContract"
                                >
                                    <i
                                        className={`fa fa-fw fa-${editing ? 'times-circle' : 'pencil'}`}
                                    />
                                    &nbsp;
                                    {editing ? 'Cancel' : 'Edit'}
                                </Button>
                            </Box>
                            {!editing && this.renderContractDocBuilder()}
                            {editing && (
                                <Box flex={1} textAlign="right">
                                    <Box component="span" mr={1}>
                                        <Button
                                            bsSize="sm"
                                            bsStyle="primary"
                                            disabled={updatingContract}
                                            onClick={() => dispatch(submit(form))}
                                            qaTag="connectedContract-updateContract"
                                        >
                                            <i className="fa fa-lg fa-pencil" /> Update Contract
                                        </Button>
                                    </Box>
                                    <OutlineButton
                                        bsSize="sm"
                                        bsStyle="danger"
                                        disabled={deletingContract || updatingContract}
                                        onClick={this.handleDeleteClick}
                                        qaTag="connectedContract-deleteContract"
                                    >
                                        <i className="fa fa-lg fa-trash" /> Delete Contract
                                    </OutlineButton>
                                </Box>
                            )}
                        </Box>
                    )}
                    {deleteContractError && (
                        <div className="error-block text-center">{deleteContractError}</div>
                    )}
                    {editing ? (
                        <ContractForm
                            buttonIcon={updatingContract ? 'fa-spin fa-spinner' : 'fa-pencil'}
                            buttonStyle="primary"
                            buttonText={
                                updatingContract ? 'Updating Contract...' : 'Update Contract'
                            }
                            contractId={contract.id}
                            contractProject={contract.project}
                            disabled={!editing || deletingContract || updatingContract}
                            isDisplayOnly={!editing}
                            onSubmit={this.handleSubmit}
                        />
                    ) : (
                        <ContractDetails
                            isAwardedVendor={isAwardedVendor}
                            isPublicView={isPublicView}
                            timezone={timezone}
                        />
                    )}
                    {updateContractError && (
                        <div className="error-block text-center">{updateContractError}</div>
                    )}
                </div>
            </Main>
        );
    }
}

export const Contract = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedContract);

// NOTE: we can't use decorators below because they don't seem to play nice with not being after the
// previously defined component, so we use the standard HOC style instead.
const PublicContract = (props) => {
    return <Contract isPublicView {...props} />;
};
Contract.Public = connect((state) => {
    const contract = getContractJS(state);
    const government = getGovernmentJS(state);
    const user = getUserJS(state);

    return {
        isAwardedVendor: !!isUserAwardedVendor(user, contract),
        timezone: government.organization.timezone,
    };
})(PublicContract);

const GovernmentContract = (props) => {
    return <Contract {...props} />;
};
Contract.Government = connect((state) => {
    return {
        isEditor: isContractEditor(state),
        project: getProjectJS(state),
        timezone: getUserOrganizationTimezone(state),
    };
})(GovernmentContract);
