import React from 'react';

import {
    Box,
    Button,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@og-pro/ui';
import EditIcon from '@mui/icons-material/Edit';
import Icon from '@mdi/react';
import { mdiTrashCan } from '@mdi/js';
import PropTypes from 'prop-types';

function createColumn(title, align = 'left') {
    return { title, align };
}

const columns = [
    createColumn('Insurance Type'),
    createColumn('Effective Date'),
    createColumn('Expiration Date'),
    createColumn('Coverage'),
    createColumn('Actions', 'center'),
];

export const ContractInsurancesTable = ({ rows, handleOpenModal, handleDeleteInsurance }) => {
    return (
        <TableContainer sx={{ mt: 2 }}>
            <MuiTable>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell align={column.align} key={column.title}>
                                {column.title}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.insuranceType}</TableCell>
                            <TableCell>{row.effectiveDate}</TableCell>
                            <TableCell>{row.expirationDate}</TableCell>
                            <TableCell sx={{ whiteSpace: 'pre-wrap' }}>
                                {row.coverage.join('\n')}
                            </TableCell>
                            <TableCell align="center">
                                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                                    <Button
                                        color="primary"
                                        onClick={() => handleOpenModal(row)}
                                        startIcon={<EditIcon />}
                                        variant="text"
                                    >
                                        Edit
                                    </Button>
                                    <Icon
                                        color="red"
                                        onClick={() => handleDeleteInsurance(row)}
                                        path={mdiTrashCan}
                                        size={1}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
};

ContractInsurancesTable.propTypes = {
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            insuranceType: PropTypes.string.isRequired,
            effectiveDate: PropTypes.string.isRequired,
            expirationDate: PropTypes.string.isRequired,
            coverage: PropTypes.string.isRequired,
        })
    ).isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    handleDeleteInsurance: PropTypes.func.isRequired,
};
