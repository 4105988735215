import { Box, Chip, FormLabel, Typography, Link } from '@og-pro/ui';
import { Lock as LockIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray } from 'redux-form';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { vendorSelectionStates } from '@og-pro/shared-config/requisitions';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { useGetAssociatedVendorData } from './hooks';
import { MismatchContractDialog } from './MismatchContractDialog';
import { Vendors } from './Vendors';
import { fieldNames } from '../../constants';
import { getInitialVendorSearch, requisitionsCreateFormValueSelector } from '../../selectors';
import { qaTagPageName } from '../../../../RequisitionGroups/ReviewSequenceEdit/constants';
import { setInitialVendorSelectionInputData } from '../../../../../../actions/requisitionsCreate';
import { InputText, RadioGroup } from '../../../../../../components';
import { limitTextLength } from '../../../../../../Forms/normalizers';
import { getRequisitionEndsInPurchaseOrder } from '../../../../../../selectors/govApp';
import { useVendorSelectionFormDisplay } from '../../../../../../lib/ogFinancials/useVendorSelection';
import { usePavilionSearch } from '../../../../../../lib/pavilion/usePavilionSearch';
import { getUserJS } from '../../../../../App/selectors';

const { VENDORS, ADDITIONAL_VENDOR_NOTES, VENDOR_SELECTION_STATE } = fieldNames;
const { NO_VENDORS, SELECTED_VENDORS } = vendorSelectionStates;

export const VendorSelection = ({ change, disabled, isApprovalView, showFormValidation }) => {
    const { showInput } = useSelector(getInitialVendorSearch);

    const [showAddVendorInput, setShowAddVendorInput] = useState(showInput);
    const [mismatchModalData, setMismatchModalData] = useState({ show: false, data: null });

    const dispatch = useDispatch();
    const styles = require('./index.scss');
    const sharedStyles = require('../../shared.scss');

    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);
    const vendorSelectionFormDisplay = useVendorSelectionFormDisplay();
    const vendorSelectionState = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, VENDOR_SELECTION_STATE)
    );
    const vendors = useSelector((state) => requisitionsCreateFormValueSelector(state, VENDORS));

    const hideMismatchModal = () => setMismatchModalData({ show: false, data: null });

    const showMismatchModal = ({ contract, vendor, ...data }) =>
        setMismatchModalData({ show: true, data: { contract, vendor, ...data } });

    // this size is arbitrary, it is just here to prevent unreasonably large inputs
    const normalizeText = limitTextLength(2048);
    const { organization } = useSelector(getUserJS);
    useEffect(() => {
        if (showInput) {
            // Reset the initial show vendor selection input value after its used on first render
            dispatch(setInitialVendorSelectionInputData());
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (vendorSelectionState === NO_VENDORS) {
            dispatch(change(VENDORS, []));
        }
        if (vendorSelectionState === SELECTED_VENDORS && (!vendors || vendors.length === 0)) {
            setShowAddVendorInput(true);
        }
    }, [vendorSelectionState]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        loading: loadingVendorData,
        vendors: associatedVendorData,
        contracts: associatedContractData,
    } = useGetAssociatedVendorData({
        endsInPurchaseOrder,
        vendors,
    });

    const enablePavilionSearch = useFlags(FLAGS.ENABLE_EMBEDDED_PAVILION_SEARCH);
    const nameOfRequest = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, fieldNames.DESCRIPTION_OF_REQUEST)
    );
    const zip = organization?.zipCode;
    const governmentAffiliation = organization?.name;

    const { isError, data: pavilionResults } = usePavilionSearch({
        searchString: nameOfRequest,
        enablePavilionSearch,
    });
    const pavilionSearchUrl = useMemo(() => {
        if (!nameOfRequest) return null;
        const baseUrl = 'https://www.withpavilion.com/contract-search';
        const params = new URLSearchParams({
            'analytics-search-source': 'widget-opengov',
            'widget-search-source': 'widget-opengov',
            query: nameOfRequest,
        });

        params.append('zip', zip);
        params.append('governmentAffiliation', governmentAffiliation);

        return `${baseUrl}?${params.toString()}`;
    }, [nameOfRequest, zip, governmentAffiliation]);
    return (
        <Box
            className={classNames({ [sharedStyles.approvalContainer]: isApprovalView })}
            sx={{ ...(isApprovalView && { gap: 0 }) }}
        >
            {!isApprovalView && (
                <>
                    <h4 className={styles.header}>Have you identified a vendor? *</h4>
                    <Field
                        component={RadioGroup}
                        disabled={disabled}
                        groupLabel="vendorSelection"
                        name={VENDOR_SELECTION_STATE}
                        options={[
                            {
                                name: 'Yes',
                                value: SELECTED_VENDORS,
                                qaTag: `${qaTagPageName}-vendorSelectionYes`,
                            },
                            {
                                name: 'Not at this time.',
                                value: NO_VENDORS,
                                qaTag: `${qaTagPageName}-vendorSelectionNo`,
                            },
                        ]}
                        showValidation={showFormValidation}
                        useOpenGovStyle
                    />
                </>
            )}
            {(vendorSelectionState === SELECTED_VENDORS || isApprovalView) && (
                <>
                    <FieldArray
                        associatedContractData={associatedContractData}
                        associatedVendorData={associatedVendorData}
                        change={change}
                        component={Vendors}
                        disabled={disabled}
                        endsInPurchaseOrder={endsInPurchaseOrder}
                        isApprovalView={isApprovalView}
                        loadingVendorData={loadingVendorData}
                        name={VENDORS}
                        setShowAddVendorInput={setShowAddVendorInput}
                        showAddVendorInput={showAddVendorInput}
                        showFormValidation={showFormValidation}
                        showMismatchModal={showMismatchModal}
                        vendorSelectionFormDisplay={vendorSelectionFormDisplay}
                        vendorSelectionState={vendorSelectionState}
                    />

                    {vendors?.length > 0 && (
                        <Field
                            component={InputText}
                            label={
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Typography marginBottom={0.5} variant="h4">
                                        Additional Vendor Notes{' '}
                                        <span style={{ fontWeight: 'normal' }}>(Optional)</span>
                                    </Typography>
                                    <Box>
                                        <Chip
                                            icon={<LockIcon />}
                                            label="Internal"
                                            size="small"
                                            sx={{ fontWeight: 'normal' }}
                                            variant="tag"
                                        />
                                    </Box>
                                </Box>
                            }
                            labelClassName={styles.fieldwidth}
                            minRows={4}
                            name={ADDITIONAL_VENDOR_NOTES}
                            normalize={normalizeText}
                            qaTag={`${qaTagPageName}-additionalVendorNotes`}
                            showValidation={showFormValidation}
                            type="textarea"
                            useOpenGovStyle
                        />
                    )}
                </>
            )}
            {mismatchModalData.show && (
                <MismatchContractDialog
                    data={mismatchModalData.data}
                    hideModal={hideMismatchModal}
                />
            )}

            {enablePavilionSearch && (
                <Box>
                    {isError
                        ? nameOfRequest && (
                              <Box alignItems="center" display="flex">
                                  <FormLabel
                                      sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          color: capitalDesignTokens.semanticColors.foreground
                                              .disabled,
                                      }}
                                  >
                                      Unable to connect to
                                      <img
                                          alt="Pavilion text logo"
                                          src="https://assets.procurement.opengov.com/assets/pavilion-logo.svg"
                                          style={{ height: '18px', marginLeft: '5px' }}
                                      />
                                  </FormLabel>
                              </Box>
                          )
                        : pavilionResults && (
                              <Box>
                                  <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>
                                      {pavilionResults.totalCount} Active Contracts Suggested by{' '}
                                      <img
                                          alt="Pavilion text logo"
                                          src="https://assets.procurement.opengov.com/assets/pavilion-logo.svg"
                                          style={{ height: '18px', marginLeft: '5px' }}
                                      />
                                  </FormLabel>
                                  <Link
                                      color={capitalDesignTokens.semanticColors.foreground.action}
                                      fontWeight="semiBold"
                                      href={pavilionSearchUrl}
                                      target="_blank"
                                      underline="hover"
                                      variant="bodySmall"
                                  >
                                      View All or Refine Search
                                      <OpenInNewIcon
                                          fontSize="small"
                                          sx={{ width: 16, height: 16, verticalAlign: 'middle' }}
                                      />
                                  </Link>
                              </Box>
                          )}
                </Box>
            )}
        </Box>
    );
};

VendorSelection.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isApprovalView: PropTypes.bool,
    showFormValidation: PropTypes.bool,
};
