import React, { useContext } from 'react';
import { FileUpload as FileUploadIcon } from '@mui/icons-material';
import { Box, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import {
    BaseSectionsNavBackButton,
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavNextButton,
    SectionHeaderViewTitleOnly,
} from '../../../../../../../components/SDv2';
import { TemplateEditV2FunctionsContext } from '../../../context';

export const TemplateEditV2UploadDocument = () => {
    const styles = require('./index.scss');
    const { buildRouteFunction, updateTemplateIfDirty, router } = useContext(
        TemplateEditV2FunctionsContext
    );
    const { colors } = capitalDesignTokens.foundations;
    return (
        <Box className={styles.container}>
            <Box
                bgcolor="white"
                borderBottom={`1px solid ${colors.gray200}`}
                borderTop={`1px solid ${colors.gray200}`}
            >
                <SectionHeaderViewTitleOnly
                    description="This section is intended to upload an externally drafted document."
                    title="Upload Document"
                />
            </Box>
            <Box color={colors.gray700} my={8} textAlign="center">
                <FileUploadIcon sx={{ width: 130, height: 90 }} />
                <Typography fontWeight={500} variant="h3">
                    Externally Drafted Documents Will Be Uploaded Here
                </Typography>
                <Typography variant="bodyDefault">
                    This will happen on a per document basis by contract editors.
                </Typography>
            </Box>
            <Box bgcolor="white" borderTop={`1px solid ${colors.gray200}`} py={2}>
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        <BaseSectionsNavBackButton
                            onClick={() => {
                                updateTemplateIfDirty();
                                router.push(buildRouteFunction('process-information'));
                            }}
                        >
                            Process Information
                        </BaseSectionsNavBackButton>
                    }
                    lastSectionComponent={
                        <BaseSectionsNavNextButton
                            onClick={() => {
                                updateTemplateIfDirty();
                                router.push(buildRouteFunction('attachments'));
                            }}
                        >
                            Exhibits
                        </BaseSectionsNavNextButton>
                    }
                    sections={[]}
                />
            </Box>
        </Box>
    );
};
