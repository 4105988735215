import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { Button } from '../../../Button';
import { LoadingError } from '../../../LoadingError/LoadingError';
import { LoadingSpinner } from '../../../LoadingSpinner/LoadingSpinner';
import { RevisionDiff } from '../../../connected/RevisionDiff';
import { loadAddendumDiff } from '../../../../actions/addendums';

const mapDispatchToProps = {
    loadAddendumDiff,
};

// @connect
class ConnectedAddendumDiffModal extends PureComponent {
    static propTypes = {
        addendum: PropTypes.shape({
            id: PropTypes.number.isRequired,
            diff: PropTypes.object,
        }).isRequired,
        hideModal: PropTypes.func.isRequired,
        loadAddendumDiff: PropTypes.func.isRequired,
        projectId: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loadingRevisionDiff: false,
            loadRevisionDiffError: null,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    componentDidMount() {
        const { addendum, projectId } = this.props;

        if (!addendum.diff) {
            return this.setState({ loadRevisionDiffError: 'No changes found' });
        }

        this.setState({
            loadingRevisionDiff: true,
            loadRevisionDiffError: null,
        });

        return this.props
            .loadAddendumDiff(projectId, addendum.id)
            .then((html) => {
                this.setState({
                    loadingRevisionDiff: false,
                    loadRevisionDiffError: html ? null : 'No changes found',
                });
            })
            .catch((error) => {
                this.setState({
                    loadingRevisionDiff: false,
                    loadRevisionDiffError: error.message,
                });
            });
    }

    renderBody() {
        const { loadingRevisionDiff, loadRevisionDiffError } = this.state;

        if (loadingRevisionDiff) {
            return <LoadingSpinner />;
        }

        if (loadRevisionDiffError) {
            return <LoadingError error={loadRevisionDiffError} />;
        }

        return (
            <RevisionDiff
                noSticky
                revisionDiffClassName={this.styles.revisionDiff}
                revisionSearchClassName={this.styles.revisionSearch}
            />
        );
    }

    render() {
        const { hideModal } = this.props;

        return (
            <Modal bsSize="lg" onHide={hideModal} show>
                <Modal.Body className={this.styles.modalBody}>{this.renderBody()}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={hideModal}>Done</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export const AddendumDiffModal = connect(null, mapDispatchToProps)(ConnectedAddendumDiffModal);
