import { Box, CircularProgress, Typography } from '@og-pro/ui';
import { circularProgressSizeProps } from '@opengov/capital-mui-theme';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const OpenGovSpinner = ({ size, text }) => {
    const [message, setMessage] = useState(Array.isArray(text) ? text[0] : text);

    useEffect(() => {
        if (!Array.isArray(text)) {
            return;
        }

        let messageIndex = 0;
        const interval = setInterval(() => {
            messageIndex += 1;
            if (messageIndex < text.length) {
                setMessage(text[messageIndex]);
            } else {
                messageIndex = 0;
                setMessage(text[0]);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [text]);

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
            }}
        >
            <CircularProgress
                {...circularProgressSizeProps[size]}
                color="primaryShadowed"
                size={size}
            />
            {text && <Typography variant="bodyLarge">{message}</Typography>}
        </Box>
    );
};

const Spinner = ({ centered, size, text, useOpenGovStyle }) => {
    if (useOpenGovStyle) {
        return <OpenGovSpinner {...{ centered, size, text, useOpenGovStyle }} />;
    }

    const styles = require('./LoadingSpinner.scss');
    const imageSrc = 'https://assets.procurement.opengov.com/assets/loading-spinner.gif';

    return (
        <>
            <img
                alt="Loading spinner"
                className={centered ? styles.loadingSpinnerCentered : styles.loadingSpinner}
                src={imageSrc}
            />
            {text && <div className={`${styles.text} text-info`}>{text}</div>}
        </>
    );
};

Spinner.propTypes = {
    centered: PropTypes.bool,
    size: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    useOpenGovStyle: PropTypes.bool,
};
OpenGovSpinner.propTypes = Spinner.propTypes;

export const LoadingSpinner = ({
    centered,
    className,
    noPadding,
    size = 'large',
    text,
    useOpenGovStyle,
}) => {
    const styles = require('./LoadingSpinner.scss');

    return (
        <div className={classnames(!noPadding && styles.loadingSpinnerContainer, className)}>
            <Spinner
                centered={centered}
                size={size}
                text={text}
                useOpenGovStyle={useOpenGovStyle}
            />
        </div>
    );
};

LoadingSpinner.propTypes = {
    centered: PropTypes.bool,
    className: PropTypes.string,
    noPadding: PropTypes.bool,
    size: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    useOpenGovStyle: PropTypes.bool,
};
