import PropTypes from 'prop-types';
import { get, round } from 'lodash';
import React, { PureComponent } from 'react';

import { HtmlContent } from '../../../../../../components/HtmlContent/HtmlContent';
import { roundNumber, scoreColor, scoreText } from '../../../../../../helpers';

export class ExpandedScoresList extends PureComponent {
    static propTypes = {
        isConsensusView: PropTypes.bool,
        proposals: PropTypes.array.isRequired,
        scoringCriteria: PropTypes.array.isRequired,
        showAnonymizedEvaluators: PropTypes.bool,
        showPercentageTotals: PropTypes.bool.isRequired,
        totalWeight: PropTypes.number.isRequired,
    };

    totalScoringCriteriaData = {
        maxScore: 100,
        title: 'Total YOO Score',
    };

    getValueStyle = ({ isTotal, score, scoringCriterium, totalScorePercentage }) => {
        const { showPercentageTotals } = this.props;

        return {
            backgroundColor: scoreColor({
                criteriaScore: score,
                isDocx: true,
                isTotal,
                scoringCriteria: scoringCriterium,
                showPercentageTotals,
                totalScorePercentage,
            }),
            fontSize: '12pt',
            textAlign: 'center',
        };
    };

    renderConsensusScoreItems(proposal) {
        const { scoringCriteria, totalWeight } = this.props;

        const scores = proposal.proposalEvaluation.submittedProposalCriteriaScores;

        return scoringCriteria.map((criterium, idx) => {
            const { description, scoringMethodText, title, weight } = criterium;

            const percent = `${round((weight / totalWeight) * 100, 1)}%`;
            const comment = get(scores, [idx, 'comment']);
            const value = get(scores, [idx, 'score']);
            const valueStyle = this.getValueStyle({
                isTotal: false,
                score: value,
                scoringCriterium: criterium,
            });

            return (
                <div key={`${proposal.id} ${idx}`} style={{ fontSize: '12pt' }}>
                    <div
                        style={{
                            backgroundColor: '#1A569B',
                            color: '#FFFFFF',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        {title} | {scoringMethodText} | {weight} Points ({percent})
                    </div>
                    <div style={{ fontSize: 6 }}>&nbsp;</div>
                    <div style={valueStyle}>{value}</div>
                    {description && (
                        <div>
                            Description:
                            <HtmlContent content={description} />
                        </div>
                    )}
                    {comment && (
                        <div>
                            <br />
                            Comments:
                            <br />
                            {comment}
                        </div>
                    )}
                    <div>&nbsp;</div>
                </div>
            );
        });
    }

    renderEvaluatorScoreItems(proposal) {
        const { scoringCriteria, showAnonymizedEvaluators, totalWeight } = this.props;
        const scores = proposal.proposalEvaluations;
        const evaluators = proposal.proposalEvaluations.map(
            (proposalEvaluation) => proposalEvaluation.user
        );

        return scoringCriteria.map((criterium, i) => {
            const { scoringMethodText, title, weight } = criterium;

            const percent = `${round((weight / totalWeight) * 100, 1)}%`;

            return (
                <div key={`${proposal.id} ${i}`} style={{ fontSize: '12pt' }}>
                    <div
                        style={{
                            backgroundColor: '#1A569B',
                            color: '#FFFFFF',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        {title} | {scoringMethodText} | {weight} Points ({percent})
                    </div>
                    {evaluators.map((evaluator, j) => {
                        const comment = get(scores, [
                            j,
                            'submittedProposalCriteriaScores',
                            i,
                            'comment',
                        ]);
                        let value = get(scores, [j, 'submittedProposalCriteriaScores', i, 'score']);
                        const valueStyle = this.getValueStyle({
                            isTotal: false,
                            score: value,
                            scoringCriterium: criterium,
                        });
                        value = scoreText({
                            criteriaScore: roundNumber(value, 1),
                            scoringCriteria: criterium,
                        });
                        const evaluatorName = showAnonymizedEvaluators
                            ? `Evaluator ${j + 1}`
                            : evaluator.displayName;

                        return (
                            <React.Fragment key={evaluator.id}>
                                <div style={{ fontSize: 6 }}>&nbsp;</div>
                                <div style={valueStyle}>{`${evaluatorName}: ${value}`}</div>
                                {comment && <div>{comment}</div>}
                            </React.Fragment>
                        );
                    })}
                    <div>&nbsp;</div>
                </div>
            );
        });
    }

    render() {
        const { isConsensusView, proposals, showPercentageTotals } = this.props;

        const headerStyle = {
            color: '#FFFFFF',
            fontSize: '14pt',
            fontWeight: 'bold',
            textAlign: 'center',
        };

        return proposals.map((proposal) => {
            const { companyName, proposalEvaluation } = proposal;

            let totalScore;
            let totalScoreStyle;
            if (isConsensusView) {
                totalScore = showPercentageTotals
                    ? roundNumber(proposalEvaluation.totalScore, 2)
                    : roundNumber(proposalEvaluation.totalScoreByPoints, 2);
                totalScoreStyle = this.getValueStyle({
                    isTotal: true,
                    score: totalScore,
                    scoringCriterium: this.totalScoringCriteriaData,
                    totalScorePercentage: proposalEvaluation.totalScore,
                });
            }

            return (
                <React.Fragment key={proposal.id}>
                    <br />
                    <div
                        style={{
                            ...headerStyle,
                            backgroundColor: proposal.isExcluded ? '#777' : '#003c81',
                        }}
                    >
                        {companyName || 'Unnamed Company'}
                        {proposal.isExcluded && <p style={{ fontSize: '12pt' }}>(Excluded)</p>}
                    </div>
                    <div style={{ fontSize: 6 }}>&nbsp;</div>
                    {isConsensusView
                        ? this.renderConsensusScoreItems(proposal)
                        : this.renderEvaluatorScoreItems(proposal)}
                    {isConsensusView && (
                        <div
                            style={{
                                ...totalScoreStyle,
                                fontWeight: 'bold',
                            }}
                        >
                            Total Score: {totalScore}
                            {showPercentageTotals && totalScore && '%'}
                        </div>
                    )}
                </React.Fragment>
            );
        });
    }
}
