// Kebab case on flags
export const FLAGS = {
    TEST_FLAG: 'pro-test-flag',
    VENDOR_DISCOVERY_AI: 'pro-vendor-discovery-ai',
    PLATFORM_PASSWORD_RESET: 'pro-platform-password-reset',
    ACCOUNT_SPLIT_AUTO_ADJUSTMENTS: 'pro-requisition-line-item-account-split-auto-adjustments',
    SHOW_SERVICE_CLOUD_CHAT_BOT: 'pro-show-service-cloud-chat-bot',
    ENABLE_REACT_TABLE_8: 'pro-enable-react-table-8',
    ATTACHMENTS_SCANNING: 'pro-4363-attachment-scanning',
    ALL_PROPOSALS_REPORT: 'pro-all-proposals-report',
    SHOW_CERTIFICATIONS_IMPORT: 'pro-4468-enable-vendor-certifications-import',
    DOING_BUSINESS_AS: 'pro-5274-doing-business-as-field',
    ENABLE_CONTRACT_DOCUMENTS_TAB: 'pro-6888-create-new-documents-tab-on-contracts',
    ENABLE_E_SIGN_EXTERNAL_AGREEMENT: 'pro-7140-e-sign-external-agreement',
    ENABLE_VENDOR_LISTS_UPDATE: 'pro-5282-updated-vendor-lists',
    ENABLE_PUBLIC_CONTRACTS_TAB: 'pro-5387-public-contracts-tab',
    ENABLE_SINGLE_SECTION_EXPORT: 'pro-5862-enable-single-section-export',
    ENABLE_NEW_INTERNAL_VENDOR_MANAGEMENT_TABLE: 'pro-enable-new-internal-vendor-management-table',
    ENABLE_CONTRACT_PACKAGE_COMPILER: 'pro-6522-contract-package-compiler',
    ENABLE_CUSTOM_FIELDS: 'pro-enable-custom-field-ui',
    ENABLE_MOVE_CONTRACTS_SUPPORT_TOGGLE_TO_SITE: 'pro-6755-move-contracts-support-toggle-to-site',
    RECORDS_RETENTION_ENHANCEMENTS: 'pro-6359-records-retention-enhancements',
    EXPORT_BUTTON_V2: 'pro-6794-export-button-v-2',
    ENABLE_TEMPLATE_INGESTION_AI: 'pro-template-ingestion-ai',
    ENABLE_EMBEDDED_PAVILION_SEARCH: 'pro-7930-embedded-pavilion-search',
    ENABLE_TRIAGE_BASED_STEPS: 'pro-6593-triage-based-reviewer-assignment-in-workflow-sequence',
    ENABLE_GENERAL_INFO_MUI_COMPONENTS: 'pro-7942-mui-components-in-general-information-section',
    ENABLE_PURCHASING_DETAILS_MUI_COMPONENTS:
        'pro-7943-mui-components-in-purchasing-details-section',
    ENABLE_OG_GRID: 'pro-8060-enable-og-grid',
    ENABLE_FLEXIBLE_PERCENTAGE_COLUMN: 'pro-7899-flexible-percentage-column-price-tables',
    ENABLE_AUTOSAVE_IN_PREVIEW: 'pro-8038-autosave-in-preview-view',
};
