import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box, Typography } from '@og-pro/ui';

import { attachmentTypesDict } from '@og-pro/shared-config/attachments';
import { projectTypesDict } from '@og-pro/shared-config/projects';
import { FLAGS, withFlags } from '@og-pro/launch-darkly/client';

import { fieldNames, projectFormProps } from '../constants';
import { FormError, HelpToolTip, ProjectAttachmentBlock } from '../../../../components';
import { ListError } from '../../../../components/GovApp';

const {
    INTERNAL,
    OTHER,
    PROJECT_DOCUMENT,
    SIGNED_DOCUMENT,
    SIGNED_DOCUMENT_CERTIFICATE,
    COMPILED_DOCUMENT,
    EXTERNAL_DOCUMENT,
} = attachmentTypesDict;
const { ATTACHMENTS } = fieldNames;

export class ConnectedAttachments extends PureComponent {
    static propTypes = {
        ...projectFormProps,
        allowTypeReassignment: PropTypes.bool,
        getFlag: PropTypes.func.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        showInternalAttachments: PropTypes.bool,
        showInternalAttachmentsOnly: PropTypes.bool,
        standalone: PropTypes.bool,
        triggerUpdate: PropTypes.func,
    };

    componentDidMount() {
        const {
            markBuilderSectionVisited,
            project: { attachments },
        } = this.props;

        markBuilderSectionVisited({
            hasIncludedItems: attachments.length > 0,
            onClose: this.hideHelpModal,
        });
    }

    formKey = ATTACHMENTS;

    get styles() {
        return require('./index.scss');
    }

    showHelpModal = () => {
        const { showHelpModal } = this.props;

        showHelpModal();
    };

    getAllowedReassignmentTypes = (type) => {
        const {
            allowTypeReassignment,
            getFlag,
            project,
            showInternalAttachments,
            showInternalAttachmentsOnly,
        } = this.props;

        const contractPackageCompilerEnabled = getFlag(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER);
        const hide = contractPackageCompilerEnabled && project.type === projectTypesDict.CONTRACT;

        if (!allowTypeReassignment || hide) {
            return null;
        }

        const internal = showInternalAttachments
            ? [{ label: 'Internal Documents', value: INTERNAL }]
            : [];
        const projectDocument =
            project.template.omitDocx && !showInternalAttachmentsOnly
                ? [{ label: 'Main Documents', value: PROJECT_DOCUMENT }]
                : [];
        const other = !showInternalAttachmentsOnly
            ? [{ label: `Supporting Attachments`, value: OTHER }]
            : [];

        switch (type) {
            case PROJECT_DOCUMENT: {
                return [...other, ...internal];
            }
            case OTHER: {
                return [...projectDocument, ...internal];
            }
            case INTERNAL: {
                return [...projectDocument, ...other];
            }
            default: {
                return null;
            }
        }
    };

    render() {
        const {
            builderDisplayName,
            disabled,
            form,
            getFlag,
            isOGThemeEnabledForComponents,
            project,
            showFormErrors,
            showInternalAttachments,
            showInternalAttachmentsOnly,
            standalone,
            updateError,
            triggerUpdate,
        } = this.props;

        const contractPackageCompilerEnabled = getFlag(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER);
        const isExhibit =
            project.type === projectTypesDict.CONTRACT && contractPackageCompilerEnabled;
        const title = isExhibit ? 'Exhibits' : 'Attachments';

        return (
            <div
                className={classnames({
                    [`row ${this.styles.container}`]: !isOGThemeEnabledForComponents,
                })}
            >
                <div
                    className={classnames({
                        'col-md-offset-1 col-md-10': !isOGThemeEnabledForComponents,
                    })}
                >
                    <FormError error={updateError} />
                    <FieldArray
                        component={ListError}
                        name={this.formKey}
                        showError={!!showFormErrors}
                    />
                    {project.template.omitDocx && !showInternalAttachmentsOnly && (
                        <div
                            className={classnames({
                                [this.styles.attachmentBlock]: !isOGThemeEnabledForComponents,
                                [this.styles.attachments]: isOGThemeEnabledForComponents,
                            })}
                        >
                            {!isOGThemeEnabledForComponents && (
                                <>
                                    <p>{`${builderDisplayName} Documents`}</p>
                                    <HelpToolTip
                                        onClick={this.showHelpModal}
                                        text="Click to see instructions"
                                    />
                                    <p>Please upload the project documents</p>
                                </>
                            )}
                            {isOGThemeEnabledForComponents && (
                                <div className={this.styles.heading}>
                                    <Typography variant="h3">Main Documents</Typography>
                                    <Typography>
                                        Add the primary project documents below.
                                    </Typography>
                                </div>
                            )}

                            <ProjectAttachmentBlock
                                allowedReassignmentTypes={this.getAllowedReassignmentTypes(
                                    PROJECT_DOCUMENT
                                )}
                                attachmentListLabel={
                                    isOGThemeEnabledForComponents
                                        ? 'Uploaded Documents'
                                        : 'Uploaded Project Documents:'
                                }
                                disabled={disabled}
                                emptyListSubtitle="Add a document above to see it here"
                                emptyListTitle="No Documents"
                                form={form}
                                formKey={PROJECT_DOCUMENT}
                                hideAppendixLetter
                                hideNoAttachments
                                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                                label="Project Documents"
                                listAttachmentType={PROJECT_DOCUMENT}
                                projectId={project.id}
                                triggerUpdate={triggerUpdate}
                                uploadAttachmentType={PROJECT_DOCUMENT}
                            />
                        </div>
                    )}
                    {!showInternalAttachmentsOnly && (
                        <div
                            className={classnames({
                                [this.styles.attachmentBlock]: !isOGThemeEnabledForComponents,
                                [this.styles.attachments]: isOGThemeEnabledForComponents,
                            })}
                        >
                            {!isOGThemeEnabledForComponents && (
                                <>
                                    <p>{`${builderDisplayName} ${title}`}</p>
                                    <HelpToolTip
                                        onClick={this.showHelpModal}
                                        text="Click to see instructions"
                                    />
                                    <p>
                                        Please upload all file attachments to include with your{' '}
                                        {builderDisplayName.toLowerCase()}
                                    </p>
                                </>
                            )}
                            {isOGThemeEnabledForComponents && !standalone && (
                                <div className={this.styles.heading}>
                                    <Typography variant="h3">
                                        {isExhibit ? 'Exhibits' : 'Supporting Attachments'}
                                    </Typography>
                                    <Typography>
                                        {isExhibit
                                            ? 'Upload and manage all documents that should be included in this contract packet.'
                                            : 'Add any supporting documents that you would like to include with the project.'}
                                    </Typography>
                                </div>
                            )}
                            <ProjectAttachmentBlock
                                allowedReassignmentTypes={this.getAllowedReassignmentTypes(OTHER)}
                                attachmentListLabel={
                                    isOGThemeEnabledForComponents ? `Uploaded ${title}` : null
                                }
                                disabled={disabled}
                                emptyListSubtitle={`Add an ${title.replace('s', '').toLowerCase()} above to see it here`}
                                emptyListTitle={`No ${title.toLowerCase()}`}
                                form={form}
                                formKey={OTHER}
                                hideNoAttachments
                                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                                label={title}
                                listAttachmentExcludeType={[
                                    PROJECT_DOCUMENT,
                                    INTERNAL,
                                    SIGNED_DOCUMENT,
                                    SIGNED_DOCUMENT_CERTIFICATE,
                                    COMPILED_DOCUMENT,
                                    EXTERNAL_DOCUMENT,
                                ]}
                                projectId={project.id}
                                triggerUpdate={triggerUpdate}
                                uploadAttachmentType={OTHER}
                            />
                        </div>
                    )}

                    {showInternalAttachments && (
                        <div className={this.styles.attachments}>
                            <div className={this.styles.heading}>
                                <Box display="flex" justifyItems="space-between">
                                    <Box flex={5}>
                                        <Typography variant="h3">Internal Documents</Typography>
                                    </Box>
                                    <Box flex={1} textAlign="right">
                                        <span className={this.styles.badge}>
                                            <i className="fa fa-lock" /> Internal Only
                                        </span>
                                    </Box>
                                </Box>

                                <Typography>
                                    {showInternalAttachmentsOnly
                                        ? 'Include any documents that will support the creation of the project. These documents are for internal use only.'
                                        : 'These documents are for internal use only. The vendor will not see them. (Some may have come over from the Intake Request.)'}
                                </Typography>
                            </div>
                            <ProjectAttachmentBlock
                                allowedReassignmentTypes={this.getAllowedReassignmentTypes(
                                    INTERNAL
                                )}
                                attachmentListLabel="Uploaded Documents"
                                disabled={disabled}
                                emptyListTitle={`No ${title}`}
                                form={form}
                                formKey={INTERNAL}
                                hideAppendixLetter
                                hideNoAttachments
                                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                                label={`Internal ${title}`}
                                listAttachmentType={INTERNAL}
                                projectId={project.id}
                                triggerUpdate={triggerUpdate}
                                uploadAttachmentType={INTERNAL}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const Attachments = compose(withFlags())(ConnectedAttachments);
