import React from 'react';
import { useSelector } from 'react-redux';

import { userRolePermissions } from '@og-pro/shared-config/userRoles';

import { AddUserButton } from '../AddUserButton';
import { AdminUserListButton } from '../AdminUserListButton';
import { isSystemAdminUser } from '../../../containers/selectors';

const { IS_SYSTEM_ADMIN } = userRolePermissions;

export const InviteUserHelp = () => {
    const isSystemAdmin = useSelector(isSystemAdminUser);
    const styles = require('./InviteUserHelp.scss');

    const renderAdminHelp = () => {
        return (
            <AddUserButton
                buttonProps={{
                    bsStyle: 'link',
                    className: styles.adminHelp,
                    qaTag: 'inviteUserHelp-inviteThem',
                    zeroPadding: true,
                }}
            >
                Invite them
            </AddUserButton>
        );
    };

    const renderNormalUserHelp = () => {
        return (
            <span>
                <AdminUserListButton
                    className={styles.adminButton}
                    permissionType={IS_SYSTEM_ADMIN}
                    text="Ask a system admin"
                />{' '}
                to invite them
            </span>
        );
    };

    return (
        <div className={`text-muted ${styles.container}`}>
            Don&#39;t see who you&#39;re looking for?&nbsp;
            {isSystemAdmin ? renderAdminHelp() : renderNormalUserHelp()}.
        </div>
    );
};

export default InviteUserHelp;
