import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Box, Button, CircularProgress } from '@og-pro/ui';

const ConnectedDashboardQueryForm = ({ loading, handleSubmit }) => (
    <form className="dashboard-query-form" onSubmit={handleSubmit}>
        <Box alignItems="center" display="flex" mb={2}>
            <Box component="label" htmlFor="nlQuery" mr={1}>
                Get a custom AI-generated chart:
            </Box>
            <Box flexGrow={1} mr={0.5}>
                <Field
                    className="form-control"
                    component="input"
                    name="nlQuery"
                    placeholder="Show me x grouped by y"
                    type="text"
                />
            </Box>
            <Box>
                <Button
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmit}
                    qaTag="dashboardQueryForm-submit"
                    startIcon={loading ? <CircularProgress size="small" /> : null}
                    variant="contained"
                >
                    {loading ? 'Generating' : 'Submit'}
                </Button>
            </Box>
        </Box>
    </form>
);

ConnectedDashboardQueryForm.propTypes = {
    loading: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
};

export const DashboardQueryForm = reduxForm({
    form: 'dashboardQueryForm',
})(ConnectedDashboardQueryForm);
