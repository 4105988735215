import { orderBy, startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DropdownButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { questionLogicLinkableModelNames } from '@og-pro/shared-config/questionLogics';
import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { projectSocket } from '../../../../lib/sockets';
import {
    getDeserializedTemplateProject,
    getDocxTemplateSelectOptions,
    getTemplateFormValues,
    getUpfrontQuestionsUsedInQuestionLogic,
} from './selectors';
import { QuestionLogicModal } from '../components';
import { ConditionalLogicModal } from '../../ConditionalLogicModal';
import { getShowConditionalLogicModal } from '../../ConditionalLogicModal/selectors';
import {
    TemplateForm,
    TemplateProjectContentForm,
    TemplateProjectSectionsForm,
    TemplateQuestionLogicForm,
    TemplateUpfrontQuestionsForm,
} from '../forms';
import { TemplateSummaryBackgroundTimelineForm } from '../forms/ProjectContentForm/components';
import {
    ALL_SECTIONS,
    fieldNames,
    form,
    FORM_SECTIONS_KEY,
    formSections,
    formSectionNames,
    incompleteSectionInstructions,
} from '../forms/constants';
import { validate } from '../forms/validate';
import { getProjectSectionTagOptions, getTemplatesAdminPath } from '../selectors';
import { getTemplateVariableOptions } from '../../constants';
import connectData from '../../../ConnectData';
import {
    getProcurementClassificationSelectOptions,
    getShowConnectionAlert,
    isInitialClientLoaded,
} from '../../../selectors';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { joinProject, leaveProject } from '../../../../actions/projectSocket';
import {
    hideConnectionAlert,
    reconnectionAlert,
    showConnectionAlert,
} from '../../../../actions/notification';
import { loadTemplateQuestionsList } from '../../../../actions/templateQuestions';
import {
    copyTemplate,
    createProjectSection,
    createTemplateQuestion,
    createTemplateSection,
    createUpfrontQuestion,
    deleteTemplate,
    loadTemplate,
    loadTemplateSections,
    reloadTemplate,
    resetTemplateEdit,
    showQuestionLogicModal,
    updateTemplate,
} from '../../../../actions/templatesAdmin';
import {
    Button,
    LoadingError,
    LoadingSpinner,
    Main,
    MenuItem,
    PageTitle,
    RouteLeaveWarning,
    SectionContainer,
    TemplateReviewSection,
    TemplateStatusLabel,
} from '../../../../components';
import { ConnectedClients } from '../../../../components/connected';
import {
    QuestionnaireMultipleChoiceFormContext,
    RichTextEditorContext,
} from '../../../../components/contexts';
import { shouldError } from '../../../../Forms/validation';
import { collapseCriteriaMap } from '../../../../helpers';
import { isPurchaseWithoutIntroduction } from '../../../../helpers/projectSections';
import { getTemplateAdminUserList } from '../../../../selectors/govApp';

import { usesDocxHeadingFormatters as getUsesDocxHeadingFormatters } from '../../selectors';

const { TEMPLATE, UPFRONT_QUESTIONNAIRE, SECTIONS, CONTENT, AUTOMATION_LOGIC } = formSectionNames;

const { LINKED_UPFRONT_QUESTION, LINKED_QUESTIONNAIRE } = questionLogicLinkableModelNames;

const { DIVIDER, TEXT_AREA } = sectionTypeNames;

const { PROJECT_SECTIONS, USE_SECTION_DIVIDERS } = fieldNames;

function fetchData(getState, dispatch, location, params) {
    const templateId = Number.parseInt(params.templateId, 10);

    return Promise.all([
        dispatch(loadTemplate(templateId)),
        dispatch(loadTemplateSections(templateId)),
        dispatch(loadTemplateQuestionsList()),
    ]);
}

const mapStateToProps = (state, props) => {
    const templateProject = getDeserializedTemplateProject(state);
    const formValues = getTemplateFormValues(state);
    const isClientLoaded = isInitialClientLoaded(state);

    return {
        copyingTemplate: state.templatesAdmin.get('copyingTemplate'),
        copyTemplateError: state.templatesAdmin.get('copyTemplateError'),
        createProjectSectionError: state.templatesAdmin.get('createProjectSectionError'),
        createUpfrontQuestionError: state.templatesAdmin.get('createUpfrontQuestionError'),
        creatingProjectSection: state.templatesAdmin.get('creatingProjectSection'),
        creatingUpfrontQuestion: state.templatesAdmin.get('creatingUpfrontQuestion'),
        deleteError: state.templatesAdmin.get('deleteError'),
        deleting: state.templatesAdmin.get('deleting'),
        docxTemplateSelectOptions: getDocxTemplateSelectOptions(state),
        formValues,
        initialValues: isClientLoaded ? templateProject : undefined,
        isClientLoaded,
        loadError: state.templatesAdmin.get('loadError'),
        loading: state.templatesAdmin.get('loading'),
        procurementClassificationSelectOptions: getProcurementClassificationSelectOptions(state),
        shouldShowConditionalLogicModal: getShowConditionalLogicModal(state),
        shouldShowConnectionAlert: getShowConnectionAlert(state),
        shouldShowQuestionLogicModal: state.templatesAdmin.get('showQuestionLogicModal'),
        usesDocxHeadingFormatters: getUsesDocxHeadingFormatters(state),
        tagOptions: getProjectSectionTagOptions(state),
        templateAdmins: getTemplateAdminUserList(state),
        templateProject,
        templatesAdminPath: getTemplatesAdminPath(state, props),
        templateVariableOptions: getTemplateVariableOptions({
            project: formValues,
        }),
        updateError: state.templatesAdmin.get('updateError'),
        updating: state.templatesAdmin.get('updating'),
        upfrontQuestionsUsedInQuestionLogicSet: getUpfrontQuestionsUsedInQuestionLogic(state),
    };
};

const mapDispatchToProps = {
    copyTemplate,
    createProjectSection,
    createTemplateQuestion,
    createTemplateSection,
    createUpfrontQuestion,
    deleteTemplate,
    hideConnectionAlert,
    joinProject,
    leaveProject,
    reconnectionAlert,
    reloadTemplate,
    resetTemplateEdit,
    showConfirmationSimpleModal,
    showConnectionAlert,
    showQuestionLogicModal,
    updateTemplate,
};

const formConfig = {
    enableReinitialize: true,
    form,
    shouldError,
    validate,
};

// @withRouter
// @connectData
// @connect
// @reduxForm
class ConnectedTemplateEdit extends Component {
    static propTypes = {
        array: PropTypes.object.isRequired,
        change: PropTypes.func.isRequired,
        copyingTemplate: PropTypes.bool.isRequired,
        copyTemplate: PropTypes.func.isRequired,
        copyTemplateError: PropTypes.string,
        createProjectSection: PropTypes.func.isRequired,
        createProjectSectionError: PropTypes.string,
        createTemplateQuestion: PropTypes.func.isRequired,
        createTemplateSection: PropTypes.func.isRequired,
        createUpfrontQuestion: PropTypes.func.isRequired,
        createUpfrontQuestionError: PropTypes.string,
        creatingProjectSection: PropTypes.bool.isRequired,
        creatingUpfrontQuestion: PropTypes.bool.isRequired,
        deleteError: PropTypes.string,
        deleteTemplate: PropTypes.func.isRequired,
        deleting: PropTypes.bool.isRequired,
        dirty: PropTypes.bool.isRequired,
        docxTemplateSelectOptions: PropTypes.array.isRequired,
        formValues: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideConnectionAlert: PropTypes.func.isRequired,
        initialize: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        isClientLoaded: PropTypes.bool.isRequired,
        joinProject: PropTypes.func.isRequired,
        leaveProject: PropTypes.func.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        params: PropTypes.shape({
            templateId: PropTypes.string.isRequired,
        }).isRequired,
        procurementClassificationSelectOptions: PropTypes.array.isRequired,
        reconnectionAlert: PropTypes.func.isRequired,
        reloadTemplate: PropTypes.func.isRequired,
        resetTemplateEdit: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
        shouldShowConditionalLogicModal: PropTypes.bool.isRequired,
        shouldShowConnectionAlert: PropTypes.bool.isRequired,
        shouldShowQuestionLogicModal: PropTypes.bool.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showConnectionAlert: PropTypes.func.isRequired,
        showQuestionLogicModal: PropTypes.func.isRequired,
        submitFailed: PropTypes.bool.isRequired,
        tagOptions: PropTypes.array.isRequired,
        templateAdmins: PropTypes.array.isRequired,
        templateProject: PropTypes.shape({
            attachments: PropTypes.array.isRequired,
            criteria: PropTypes.object.isRequired,
            evaluationPhases: PropTypes.array.isRequired,
            id: PropTypes.number.isRequired,
            isDocBuilder: PropTypes.bool.isRequired,
            isIntake: PropTypes.bool.isRequired,
            projectSections: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    shortName: PropTypes.string.isRequired,
                })
            ).isRequired,
            questionnaires: PropTypes.array.isRequired,
            sectionDescriptions: PropTypes.object.isRequired,
            template: PropTypes.shape({
                archived: PropTypes.bool,
                docx_attachment_id: PropTypes.number,
                forceSealedBidStatus: PropTypes.bool.isRequired,
                icon: PropTypes.string,
                isPublished: PropTypes.bool.isRequired,
                isReverseAuction: PropTypes.bool.isRequired,
                isSpecial: PropTypes.bool.isRequired,
                omitDocx: PropTypes.bool.isRequired,
                processAcronym: PropTypes.string,
                substitutionRequestFormUrl: PropTypes.string,
                title: PropTypes.string,
            }).isRequired,
            type: PropTypes.string.isRequired,
        }),
        templatesAdminPath: PropTypes.string.isRequired,
        templateVariableOptions: PropTypes.array,
        updateError: PropTypes.string,
        updateTemplate: PropTypes.func.isRequired,
        updating: PropTypes.bool.isRequired,
        upfrontQuestionsUsedInQuestionLogicSet: PropTypes.instanceOf(Set).isRequired,
        usesDocxHeadingFormatters: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            openSection: null,
            showFormValidation: false,
            showGlobalFormValidation: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get templateIdFromParams() {
        // Use the param template ID in case template has not been loaded yet
        return Number.parseInt(this.props.params.templateId, 10);
    }

    reconnectHandler = () => {
        this.props.reconnectionAlert();
        this.props.joinProject(this.templateIdFromParams);
        this.props.reloadTemplate(this.templateIdFromParams);
    };

    connectErrorHandler = () => {
        this.props.showConnectionAlert();
    };

    componentDidMount() {
        projectSocket.connect();
        projectSocket.io.on('reconnect', this.reconnectHandler);
        projectSocket.on('connect_error', this.connectErrorHandler);
        this.props.joinProject(this.templateIdFromParams);
    }

    componentDidUpdate(prevProps) {
        const { initialize, isClientLoaded, templateProject } = this.props;

        /**
         * (DGW 2/6/20):
         * IMPORTANT: This form is not properly initializing on the server. As a workaround, we
         * avoid initializing on the server and instead do so here once the client has loaded.
         *
         * When the page is navigated to client side, we can initialize the form using the connected
         * `initialValues` prop.
         *
         * This initialization will get called when the form is loaded by the server (#1). The form
         * will not be initialized with values by the server and instead be initialized here
         * once the client has rendered.
         */
        if (!prevProps.isClientLoaded && isClientLoaded) {
            initialize(templateProject); // Initializes redux-form
        }
    }

    componentWillUnmount() {
        if (this.props.shouldShowConnectionAlert) {
            this.props.hideConnectionAlert();
        }

        this.props.resetTemplateEdit();
        projectSocket.io.off('reconnect', this.reconnectHandler);
        projectSocket.off('connect_error', this.connectErrorHandler);
        this.props.leaveProject(this.templateIdFromParams);
        projectSocket.disconnect();
    }

    addConditionalLogicHandler = (questionLogic) => {
        this.addQuestionLogicHandler(questionLogic);

        // Take off event loop before saving so form can finish updating
        setTimeout(this.updateTemplate);
    };

    addQuestionLogicHandler = (questionLogic) => {
        const { array } = this.props;

        array.push('questionLogics', questionLogic);
    };

    copyTemplate = () => {
        const {
            templateProject: { id },
            templatesAdminPath,
            router,
        } = this.props;

        const copyHandler = () => {
            this.props.copyTemplate(id, (result) =>
                router.push(`${templatesAdminPath}/${result.id}`)
            );
        };

        this.props.showConfirmationSimpleModal(copyHandler, {
            btnText: 'Copy Template',
            bsStyle: 'primary',
            icon: 'copy',
            text: 'Create a copy of this template?',
        });
    };

    createProjectSection = (projectSectionType) => {
        const {
            templateProject: { id },
        } = this.props;

        return this.props.createProjectSection(id, projectSectionType);
    };

    createTemplateSection = (templateSectionId) => {
        const {
            templateProject: { id },
        } = this.props;

        return this.props.createTemplateSection(id, templateSectionId);
    };

    createTemplateQuestion = (templateQuestionId, opts) => {
        const {
            templateProject: { id },
        } = this.props;

        return this.props.createTemplateQuestion(id, templateQuestionId, opts);
    };

    createUpfrontQuestion = (upfrontQuestionData) => {
        const {
            templateProject: { id },
        } = this.props;

        return this.props.createUpfrontQuestion(id, upfrontQuestionData);
    };

    toggleArchived = () => {
        const {
            formValues,
            templateProject: {
                id,
                template: { archived },
            },
        } = this.props;

        const handler = () => {
            const submitData = {
                ...formValues,
                template: {
                    ...formValues.template,
                    archived: !archived,
                },
            };

            return this.props
                .updateTemplate(id, submitData)
                .then(() => this.openSectionHandler(null));
        };

        if (!archived) {
            this.props.showConfirmationSimpleModal(handler, {
                bsStyle: 'primary',
                btnText: 'Archive Template',
                icon: 'archive',
                text: 'Are you sure you want to archive this template?\nYou may restore it later.',
            });
        } else {
            handler();
        }
    };

    deleteTemplate = () => {
        const {
            templateProject: { id },
            templatesAdminPath,
            router,
        } = this.props;

        const deleteHandler = () => {
            this.props.deleteTemplate(id, () => router.replace(templatesAdminPath));
        };

        this.props.showConfirmationSimpleModal(deleteHandler, {
            btnText: 'Delete Template',
            text: 'Are you sure you want to delete this template?\nThis action cannot be undone.',
        });
    };

    editQuestionLogicHandler = (questionLogic, index) => {
        const { array } = this.props;

        array.splice('questionLogics', index, 1, questionLogic);
    };

    openSectionHandler = (sectionNumber, showFormValidation = false) => {
        const { openSection } = this.state;

        // No section should be selected if the numbers match (open section is being closed)
        const newSectionNumber = sectionNumber === openSection ? null : sectionNumber;
        this.setState({
            openSection: newSectionNumber,
            showFormValidation,
        });
    };

    removeQuestionLogicHandler = (index, onConfirm) => {
        const { array } = this.props;

        const removeHandler = () => {
            array.remove('questionLogics', index);
            if (onConfirm) {
                onConfirm();
            }
        };

        this.props.showConfirmationSimpleModal(removeHandler, {
            text: 'Are you sure you want to remove this item?',
        });
    };

    showGlobalFormValidation = () => {
        this.setState({ showGlobalFormValidation: true });
    };

    toggleLive = () => {
        const {
            formValues,
            handleSubmit,
            templateProject: {
                id,
                template: { isPublished },
            },
        } = this.props;

        const closeOpenForms = (templateOrError) => {
            if (!(templateOrError instanceof Error)) {
                this.openSectionHandler(null);
            }
        };

        if (isPublished) {
            const submitData = {
                ...formValues,
                template: {
                    ...formValues.template,
                    isPublished: false,
                },
            };
            return this.props.updateTemplate(id, submitData).then(closeOpenForms);
        }

        this.setState({ showGlobalFormValidation: true });
        return handleSubmit((data) => {
            const submitData = {
                ...data,
                template: {
                    ...data.template,
                    isPublished: true,
                },
            };
            this.setState({ showGlobalFormValidation: false });
            return this.props.updateTemplate(id, submitData).then(closeOpenForms);
        })();
    };

    toggleUseSectionDividers = (checked) => {
        const {
            change,
            formValues: { projectSections = [] },
        } = this.props;

        if (checked) {
            this.createProjectSection(DIVIDER).then((projectSectionOrError) => {
                if (!(projectSectionOrError instanceof Error)) {
                    const updatedSections = [projectSectionOrError, ...projectSections].map(
                        (section, idx) => {
                            return { ...section, orderById: idx + 1 };
                        }
                    );
                    change(PROJECT_SECTIONS, updatedSections);
                }
            });
            change(USE_SECTION_DIVIDERS, checked);
        } else {
            const removeDividers = () => {
                const updatedSections = projectSections
                    .filter((section) => section.section_type !== DIVIDER)
                    .map((section, idx) => {
                        return { ...section, orderById: idx + 1 };
                    });
                change(PROJECT_SECTIONS, updatedSections);
                change(USE_SECTION_DIVIDERS, checked);
            };
            this.props.showConfirmationSimpleModal(removeDividers, {
                btnText: 'Remove Dividers',
                text: 'Turning off section dividers will remove all existing dividers.\nThis action cannot be undone.',
            });
        }
    };

    updateTemplate = () => {
        const {
            formValues,
            handleSubmit,
            templateProject: {
                id,
                template: { isPublished },
            },
        } = this.props;

        // If the template has been published we want to validate the form before submitting
        if (isPublished) {
            this.setState({ showGlobalFormValidation: true });
            return handleSubmit((data) => {
                this.setState({ showGlobalFormValidation: false });
                return this.props.updateTemplate(id, data);
            })();
        }

        return this.props.updateTemplate(id, formValues);
    };

    renderTemplateInfo() {
        const {
            templateProject: {
                template: { icon, processAcronym, title },
            },
        } = this.props;

        if (!title) {
            return 'No template title set';
        }

        return (
            <span>
                {icon && (
                    <>
                        <i className={`fa fa-fw fa-${icon}`} />
                        &nbsp;
                    </>
                )}
                {title}
                {processAcronym && <span>&nbsp;({processAcronym})</span>}
            </span>
        );
    }

    renderQuestionsInfo() {
        const {
            formValues: { upfrontQuestions = [] },
        } = this.props;

        if (upfrontQuestions.length === 0) {
            return 'No configuration questions set';
        }

        return (
            <div className={this.styles.contentItem}>
                <i className="fa fa-fw fa-gear" /> Configuration Questions:{' '}
                {upfrontQuestions.length}
            </div>
        );
    }

    renderProjectSectionsInfo() {
        const {
            formValues: { projectSections = [], useManualNumbering, useSectionDividers },
        } = this.props;

        if (projectSections.length === 0) {
            return 'No template sections set';
        }

        return projectSections.map((projectSection) => (
            <div
                key={projectSection.id}
                style={
                    useSectionDividers && projectSection.section_type !== DIVIDER
                        ? { paddingLeft: 20 }
                        : {}
                }
            >
                {useManualNumbering && projectSection.manualNumber && (
                    <span>{projectSection.manualNumber}&nbsp;&nbsp;&nbsp;</span>
                )}
                {projectSection.title}
            </div>
        ));
    }

    renderContentSectionsInfo() {
        const {
            formValues: {
                attachments = [],
                criteria = {},
                evaluationPhases = [],
                projectSections = [],
                questionnaires = [],
                sectionDescriptions = {},
                useSectionDividers,
            },
            templateProject: { isDocBuilder },
        } = this.props;

        const evaluationCriteriaCount = evaluationPhases.reduce((sum, phase) => {
            return sum + (phase.scoringCriteria || []).length;
        }, 0);
        const criteriaItems = collapseCriteriaMap(criteria);
        const sectionItems = collapseCriteriaMap(sectionDescriptions);

        const contentItems = [
            { item: 'Attachments', icon: 'paperclip', count: attachments.length },
            {
                item: 'List Items',
                icon: 'list-ol',
                count: criteriaItems.filter((crit) => crit.section_type !== TEXT_AREA).length,
            },
            {
                item: 'Text Areas',
                icon: 'file-text',
                count: criteriaItems.filter((crit) => crit.section_type === TEXT_AREA).length,
            },
            {
                item: 'Section Headers',
                icon: 'bookmark',
                count: sectionItems.filter((desc) => desc).length,
            },
        ];

        if (useSectionDividers) {
            contentItems.push({
                item: 'Dividers',
                icon: 'indent',
                count: projectSections.filter((sec) => sec.section_type === DIVIDER).length,
            });
        }

        if (!isDocBuilder) {
            contentItems.push(
                { item: 'Questionnaires', icon: 'question-circle', count: questionnaires.length },
                { item: 'Evaluation Criteria', icon: 'star', count: evaluationCriteriaCount }
            );
        }

        return orderBy(contentItems, ['item'], ['asc']).map((content) => (
            <div className={this.styles.contentItem} key={content.item}>
                <i className={`fa fa-fw fa-${content.icon}`} />
                &nbsp;&nbsp;
                {content.item}: {content.count}
            </div>
        ));
    }

    renderAutomationLogicInfo() {
        const {
            formValues: { questionLogics = [] },
        } = this.props;

        const questionLogicsCount = questionLogics.reduce((sum, questionLogic) => {
            // Omit conditional questions from count (cannot be updated)
            if ([LINKED_UPFRONT_QUESTION, LINKED_QUESTIONNAIRE].includes(questionLogic.linkable)) {
                return sum;
            }
            return sum + 1;
        }, 0);

        if (questionLogicsCount === 0) {
            return 'No automations set';
        }

        return (
            <div className={this.styles.contentItem}>
                <i className="fa fa-fw fa-magic" /> Automations: {questionLogicsCount}
            </div>
        );
    }

    render() {
        if (this.props.loading) {
            return <LoadingSpinner />;
        }

        if (this.props.loadError) {
            return <LoadingError error={this.props.loadError} />;
        }

        // If navigating to the page using back/forward page can render before loading function
        // starts, so need this check
        if (!this.props.templateProject) {
            return null;
        }

        const {
            array,
            change,
            copyingTemplate,
            copyTemplateError,
            createProjectSectionError,
            createUpfrontQuestionError,
            creatingProjectSection,
            creatingUpfrontQuestion,
            deleteError,
            deleting,
            dirty,
            docxTemplateSelectOptions,
            formValues: {
                hasSealedBid,
                isPrivate,
                isPublicBidPricingResult,
                projectSections = [],
                template = {},
                upfrontQuestions = [],
                useManualNumbering,
                useSectionDividers,
            },
            invalid,
            procurementClassificationSelectOptions,
            shouldShowConditionalLogicModal,
            shouldShowQuestionLogicModal,
            submitFailed,
            tagOptions,
            templatesAdminPath,
            templateAdmins,
            templateProject: {
                id: projectId,
                isDocBuilder,
                isIntake,
                template: { isPublished, isSpecial, archived },
                type,
            },
            templateVariableOptions,
            updating,
            updateError,
            upfrontQuestionsUsedInQuestionLogicSet,
            usesDocxHeadingFormatters,
        } = this.props;

        const { openSection, showFormValidation, showGlobalFormValidation } = this.state;

        const disabled =
            updating ||
            deleting ||
            creatingProjectSection ||
            creatingUpfrontQuestion ||
            copyingTemplate ||
            archived;

        const publishIcon = isPublished ? 'ban' : 'play-circle';
        const deleteIcon = deleting ? 'spinner fa-spin' : 'trash';
        const saveIcon = updating ? 'spinner fa-spin' : 'check';

        const showValidation = showFormValidation || showGlobalFormValidation;
        const hasNoIntroduction = isPurchaseWithoutIntroduction(
            this.props.formValues.type,
            projectSections
        );
        const numberOffset = hasNoIntroduction ? 1 : 0;

        return (
            <RichTextEditorContext project={this.props.templateProject}>
                <PageTitle title="Edit Template" />
                <RouteLeaveWarning blockingValue={dirty} />
                <div>
                    <Button
                        aria-label="Templates"
                        bsSize="sm"
                        bsStyle="link"
                        qaTag="templateEdit-templates"
                        to={templatesAdminPath}
                    >
                        <i className="fa fa-angle-left" /> Templates
                    </Button>
                </div>
                <Main className={this.styles.titleContainer} data-qa="templateEdit-header">
                    <div className="row">
                        <div className="col-sm-7 col-md-8">
                            <h3 className={this.styles.title}>{template.title || 'Untitled'}</h3>
                            <TemplateStatusLabel
                                className={this.styles.statusLabel}
                                isArchived={archived}
                                isArchivedLabelStyle="default"
                                isPublished={isPublished}
                                isSpecial={isSpecial}
                            />
                            <div className={`text-muted ${this.styles.typeLabel}`}>
                                {startCase(type)}
                            </div>
                        </div>
                        <div className="col-sm-5 col-md-4 text-right">
                            <DropdownButton
                                aria-label="More Actions"
                                disabled={disabled && !archived}
                                id="template-project-more-button"
                                pullRight
                                qaTag="templateEdit-moreActions"
                                title="More"
                            >
                                <MenuItem
                                    disabled={archived}
                                    onClick={this.showGlobalFormValidation}
                                    qaTag="templateAdmin-showFormErrors"
                                >
                                    <i className="fa fa-fw fa-exclamation-triangle" /> Show Form
                                    Errors
                                </MenuItem>
                                <MenuItem
                                    disabled={archived}
                                    onClick={this.copyTemplate}
                                    qaTag="templateAdmin-copyTemplate"
                                >
                                    <i className="fa fa-fw fa-copy" /> Copy Template
                                </MenuItem>
                                {!isSpecial && (
                                    <MenuItem
                                        onClick={this.toggleArchived}
                                        qaTag="templateAdmin-archiveTemplate"
                                    >
                                        {archived ? (
                                            <>
                                                <i className="fa fa-fw fa-undo" /> Restore Template
                                            </>
                                        ) : (
                                            <>
                                                <i className="fa fa-fw fa-archive" /> Archive
                                                Template
                                            </>
                                        )}
                                    </MenuItem>
                                )}
                                {!isSpecial && (
                                    <MenuItem
                                        disabled={archived}
                                        onClick={this.deleteTemplate}
                                        qaTag="templateAdmin-delete"
                                    >
                                        <i className={`fa fa-fw fa-${deleteIcon}`} /> Delete
                                    </MenuItem>
                                )}
                            </DropdownButton>
                            &nbsp;&nbsp;
                            <Button
                                aria-label="Toggle Live"
                                bsStyle={isPublished ? 'default' : 'success'}
                                disabled={disabled || isSpecial}
                                onClick={this.toggleLive}
                                qaTag="templateEdit-toggleLive"
                            >
                                <i className={`fa fa-${publishIcon}`} />
                                &nbsp;
                                {isPublished ? 'Disable' : 'Set Live'}
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                aria-label="Save"
                                bsStyle="primary"
                                disabled={disabled}
                                onClick={this.updateTemplate}
                                qaTag="templateEdit-save"
                            >
                                <i className={`fa fa-fw fa-${saveIcon}`} /> Save
                            </Button>
                            <div>
                                <ConnectedClients className={this.styles.connectedClients} />
                            </div>
                        </div>
                    </div>
                    {updateError && <div className="error-block text-right">{updateError}</div>}
                    {submitFailed && invalid && (
                        <div className="error-block text-right">
                            Please fix form errors before submitting. Invalid sections are those
                            without check marks below.
                        </div>
                    )}
                    {deleteError && <div className="error-block text-right">{deleteError}</div>}
                    {copyTemplateError && (
                        <div className="error-block text-right">{copyTemplateError}</div>
                    )}
                </Main>
                <Field
                    component={SectionContainer}
                    disabled={archived}
                    info={this.renderTemplateInfo()}
                    isOpen={openSection === 1}
                    name={`${FORM_SECTIONS_KEY}.${TEMPLATE}`}
                    number={1}
                    onClick={this.openSectionHandler}
                    title="Design your template"
                >
                    <TemplateForm
                        change={change}
                        disabled={disabled}
                        docxTemplateSelectOptions={docxTemplateSelectOptions}
                        forceSealedBidStatus={template.forceSealedBidStatus}
                        hasSealedBid={hasSealedBid}
                        isPrivate={isPrivate}
                        isPublicBidPricingResult={isPublicBidPricingResult}
                        isReverseAuction={template.isReverseAuction}
                        isSolicitation={!isDocBuilder && !isSpecial}
                        omitDocx={template.omitDocx || isSpecial}
                        procurementClassificationSelectOptions={
                            procurementClassificationSelectOptions
                        }
                        projectId={projectId}
                        showFormValidation={showValidation}
                        substitutionRequestFormUrl={template.substitutionRequestFormUrl}
                        toggleUseSectionDividers={this.toggleUseSectionDividers}
                        userOptions={templateAdmins}
                        usesCustomDocxTemplate={!!template.docx_attachment_id}
                        usesDocxHeadingFormatters={usesDocxHeadingFormatters}
                    />
                </Field>
                <Field
                    component={SectionContainer}
                    disabled={archived}
                    info={this.renderQuestionsInfo()}
                    isOpen={openSection === 2}
                    name={`${FORM_SECTIONS_KEY}.${UPFRONT_QUESTIONNAIRE}`}
                    number={2}
                    onClick={this.openSectionHandler}
                    title="Collect information (optional)"
                >
                    <QuestionnaireMultipleChoiceFormContext.Provider
                        value={{ isEditingDisabledSet: upfrontQuestionsUsedInQuestionLogicSet }}
                    >
                        <TemplateUpfrontQuestionsForm
                            addConditionalLogicHandler={this.addConditionalLogicHandler}
                            array={array}
                            change={change}
                            createTemplateQuestion={this.createTemplateQuestion}
                            createUpfrontQuestion={this.createUpfrontQuestion}
                            createUpfrontQuestionError={createUpfrontQuestionError}
                            disabled={disabled}
                            isDirty={dirty}
                            showFormValidation={showValidation}
                            tagOptions={tagOptions}
                            templateVariableOptions={templateVariableOptions}
                            updateTemplate={this.updateTemplate}
                            upfrontQuestions={upfrontQuestions}
                        />
                    </QuestionnaireMultipleChoiceFormContext.Provider>
                </Field>
                {hasNoIntroduction && (
                    <Field
                        component={SectionContainer}
                        disabled={archived}
                        isOpen={openSection === 3}
                        name={`${FORM_SECTIONS_KEY}.${SECTIONS}`}
                        number={3}
                        onClick={this.openSectionHandler}
                        title="Summary, Background, & Timeline"
                    >
                        <TemplateSummaryBackgroundTimelineForm
                            array={array}
                            change={change}
                            disabled={disabled}
                            form={form}
                            showFormValidation={showValidation}
                            tagOptions={tagOptions}
                            templateVariableOptions={templateVariableOptions}
                        />
                    </Field>
                )}

                <Field
                    component={SectionContainer}
                    disabled={archived}
                    info={this.renderProjectSectionsInfo()}
                    isOpen={openSection === 3 + numberOffset}
                    name={`${FORM_SECTIONS_KEY}.${SECTIONS}`}
                    number={3 + numberOffset}
                    onClick={this.openSectionHandler}
                    title="Add sections to your template"
                >
                    <TemplateProjectSectionsForm
                        array={array}
                        change={change}
                        createProjectSection={this.createProjectSection}
                        createProjectSectionError={createProjectSectionError}
                        createTemplateSection={this.createTemplateSection}
                        disabled={disabled}
                        isDirty={dirty}
                        isDocBuilder={isDocBuilder}
                        isIntake={isIntake}
                        isSpecial={isSpecial}
                        showConfirmationSimpleModal={this.props.showConfirmationSimpleModal}
                        showFormValidation={showValidation}
                        updateTemplate={this.updateTemplate}
                        useManualNumbering={useManualNumbering}
                        useSectionDividers={useSectionDividers}
                    />
                </Field>
                <Field
                    component={SectionContainer}
                    disabled={archived}
                    info={this.renderContentSectionsInfo()}
                    isOpen={openSection === 4 + numberOffset}
                    name={`${FORM_SECTIONS_KEY}.${CONTENT}`}
                    number={4 + numberOffset}
                    onClick={this.openSectionHandler}
                    qaTag="templateEdit-contentSection"
                    title="Define your content"
                >
                    <TemplateProjectContentForm
                        addConditionalLogicHandler={this.addConditionalLogicHandler}
                        array={array}
                        change={change}
                        disabled={disabled}
                        projectId={projectId}
                        projectSections={projectSections}
                        showFormValidation={showValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        useManualNumbering={useManualNumbering}
                        useSectionDividers={useSectionDividers}
                    />
                </Field>
                <Field
                    component={SectionContainer}
                    disabled={archived}
                    info={this.renderAutomationLogicInfo()}
                    isOpen={openSection === 5 + numberOffset}
                    name={`${FORM_SECTIONS_KEY}.${AUTOMATION_LOGIC}`}
                    number={5 + numberOffset}
                    onClick={this.openSectionHandler}
                    title="Use automation (optional)"
                >
                    <TemplateQuestionLogicForm
                        deleteQuestionLogicHandler={this.removeQuestionLogicHandler}
                        disabled={disabled}
                        showFormValidation={showValidation}
                        showQuestionLogicModal={this.props.showQuestionLogicModal}
                    />
                </Field>
                {!isPublished && (
                    <Field
                        component={SectionContainer}
                        disabled={archived}
                        isOpen={openSection === 6 + numberOffset}
                        name={ALL_SECTIONS}
                        number={6 + numberOffset}
                        onClick={this.openSectionHandler}
                        title="Review your template and set it live"
                    >
                        <Field
                            component={TemplateReviewSection}
                            disabled={disabled}
                            formSectionKey={FORM_SECTIONS_KEY}
                            formSections={formSections}
                            incompleteSectionInstructions={incompleteSectionInstructions}
                            name={ALL_SECTIONS}
                            openSectionHandler={this.openSectionHandler}
                            readyText="This template will be available for use by your team as soon as it's live"
                            saveHandler={this.updateTemplate}
                            setLiveHandler={this.toggleLive}
                        />
                    </Field>
                )}
                {shouldShowQuestionLogicModal && (
                    <QuestionLogicModal
                        onAdd={this.addQuestionLogicHandler}
                        onEdit={this.editQuestionLogicHandler}
                        onRemove={this.removeQuestionLogicHandler}
                        projectSections={projectSections}
                        upfrontQuestions={upfrontQuestions}
                    />
                )}
                {shouldShowConditionalLogicModal && (
                    <ConditionalLogicModal
                        isDirty={dirty}
                        updateTemplate={this.updateTemplate}
                        upfrontQuestions={upfrontQuestions}
                    />
                )}
            </RichTextEditorContext>
        );
    }
}

export const TemplateEdit = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedTemplateEdit);
