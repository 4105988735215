import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@og-pro/ui';
import { AutoFixHigh as WandIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { loadAddendums } from '../../../../actions/addendums';
import { showSnackbar } from '../../../../actions/notification';
import { aiEnablementData } from '../../../../containers/GovApp/App/selectors';
import { AddendumAIDialog } from './Dialog';

export const AddendumAI = ({ addendum, updateHandler }) => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const aiEnabled = useSelector(aiEnablementData);
    const [open, setOpen] = useState(false);

    const toggleDialog = useCallback(() => setOpen(!open), [open]);
    const onSaveSuccess = useCallback(() => {
        dispatch(loadAddendums(projectId, { view: 'government' }));
        dispatch(
            showSnackbar('Your report as been generated and attached to the Addenda', {
                isSuccess: true,
                dismissAfter: 5000,
            })
        );
        toggleDialog();
    }, [toggleDialog, dispatch, projectId]);

    const onUpdateClick = useCallback(
        (content) => {
            updateHandler({ ...addendum, description: content });
            toggleDialog();
        },
        [toggleDialog, updateHandler]
    );

    if (!aiEnabled) {
        return null;
    }

    return (
        <>
            <Button
                onClick={toggleDialog}
                qaTag="addendum-generateWithAI"
                size="small"
                startIcon={<WandIcon />}
                variant="outlined"
            >
                Generate with AI
            </Button>
            {open && (
                <AddendumAIDialog
                    addendum={addendum}
                    onSaveSuccess={onSaveSuccess}
                    onUpdateClick={onUpdateClick}
                    toggleDialog={toggleDialog}
                />
            )}
        </>
    );
};

AddendumAI.propTypes = {
    addendum: PropTypes.object.isRequired,
    updateHandler: PropTypes.func.isRequired,
};
