import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Modal, Button, TextField } from '@og-pro/ui';
import CloseIcon from '@mui/icons-material/Close';

export const EditInsurancesDetailModal = ({ handleClose, open, onSave, data }) => {
    const [formData, setFormData] = useState({
        insuranceType: '',
        effectiveDate: '',
        expirationDate: '',
        coverage: '',
    });

    useEffect(() => {
        if (data) {
            setFormData(data);
        }
    }, [data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        onSave(formData);
        setFormData({
            insuranceType: '',
            effectiveDate: '',
            expirationDate: '',
            coverage: '',
        });
        handleClose();
    };

    return (
        <Modal onClose={handleClose} open={open}>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    left: '50%',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 552,
                    p: 2,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="h3">Edit Insurance Details</Typography>
                    <CloseIcon onClick={handleClose} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Insurance Type"
                        name="insuranceType"
                        onChange={handleChange}
                        required
                        value={formData.insuranceType}
                    />
                    <TextField
                        label="Effective Date"
                        name="effectiveDate"
                        onChange={handleChange}
                        required
                        value={formData.effectiveDate}
                    />
                    <TextField
                        label="Expiration Date"
                        name="expirationDate"
                        onChange={handleChange}
                        required
                        value={formData.expirationDate}
                    />
                    <TextField
                        fullWidth
                        label="Coverage"
                        multiline
                        name="coverage"
                        onChange={handleChange}
                        required
                        sx={{
                            '& .MuiInputBase-multiline': {
                                width: '100%',
                            },
                        }}
                        value={formData.coverage}
                    />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1 }}>
                    <Button color="primary" onClick={handleClose} variant="contained">
                        Close
                    </Button>
                    <Button color="primary" onClick={handleSave} variant="contained">
                        Add Insurance Details
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

EditInsurancesDetailModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    data: PropTypes.shape({
        insuranceType: PropTypes.string,
        effectiveDate: PropTypes.string,
        expirationDate: PropTypes.string,
        coverage: PropTypes.string,
    }),
};

EditInsurancesDetailModal.defaultProps = {
    data: null,
};
