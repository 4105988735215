import { DATA_VISUALIZATION_PROMPT } from '@og-pro/shared-config/generativeAi';

import { resourceManager } from '../helpers';

export const GENERATE_AI_CHART_CONFIG = 'dashboard/GENERATE_AI_CHART_CONFIG';
export const GENERATE_AI_CHART_CONFIG_SUCCESS = 'dashboard/GENERATE_AI_CHART_CONFIG_SUCCESS';
export const GENERATE_AI_CHART_CONFIG_FAIL = 'dashboard/GENERATE_AI_CHART_CONFIG_FAIL';

export function generateAiChartConfig({ dataContext, prompt }, govId) {
    return resourceManager({
        method: 'post',
        url: `/government/${govId}/generative-ai`,
        requestOptions: {
            data: { dataContext, promptsKey: DATA_VISUALIZATION_PROMPT, userPrompt: prompt },
        },
        onStart: ({ dispatch }) => dispatch({ type: GENERATE_AI_CHART_CONFIG }),
        onSuccess: ({ result, dispatch }) => {
            dispatch({ type: GENERATE_AI_CHART_CONFIG_SUCCESS, result });
        },
        onFailure: ({ error, dispatch }) => {
            dispatch({ type: GENERATE_AI_CHART_CONFIG_FAIL, error });
        },
    });
}

export function clearAiChartContent() {
    return {
        type: GENERATE_AI_CHART_CONFIG,
    };
}
