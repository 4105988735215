import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@og-pro/ui';
import { getFormSyncErrors } from 'redux-form';
import { Warning as WarningIcon } from '@mui/icons-material';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { SDv2Timeline } from '../../../../../../../components/GovApp';
import { ProjectCreateV2FunctionsContext } from '../../../context';

const { colors } = capitalDesignTokens.foundations;

export const Timeline = () => {
    const timezone = useSelector((state) => state.auth.getIn(['user', 'organization', 'timezone']));
    const { array, change, disabled, form, project, showFormErrors } = useContext(
        ProjectCreateV2FunctionsContext
    );
    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));

    const hasError = showFormErrors && formErrors.timelineChronologicError;
    return (
        <Box
            sx={{
                '& > div': {
                    borderColor: hasError ? colors.red700 : 'default',
                },
            }}
        >
            <SDv2Timeline
                array={array}
                change={change}
                disabled={disabled}
                form={form}
                isReverseAuction={project.template.isReverseAuction}
                project={project}
                showDatesByDefault
                showValidation={showFormErrors}
                timezone={timezone}
            />
            {hasError && (
                <Box mt={1}>
                    <Typography
                        color="error"
                        sx={{ alignItems: 'center', display: 'flex' }}
                        variant="h4"
                    >
                        <WarningIcon color="error" /> &nbsp;Selected dates are not chronological.
                    </Typography>
                </Box>
            )}
        </Box>
    );
};
