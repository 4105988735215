import { LINE_ITEM, QUANTITY, UNIT_PRICE } from '@og-pro/shared-config/priceTables';
import { calculatePriceItemTotalCost } from '@og-pro/shared-config/priceTables/utils';

/**
 * Get the value of a cell based on the price item data from the vendor response or government
 * entered value
 *
 * @param {string} fieldName The name of the field to get the value for
 * @param {PriceItem} priceItem The price item to get the value of the field from
 * @return {*} The value for the cell
 */
export const getPriceItemValue = (fieldName, priceItem) => {
    // prevent price items from overriding the vendor response if it was no bid
    if (
        (fieldName === UNIT_PRICE || fieldName === QUANTITY) &&
        priceItem.vendorResponse &&
        priceItem.vendorResponse.noBid
    ) {
        return priceItem.vendorResponse[fieldName];
    }

    if (
        priceItem.vendorResponse &&
        priceItem.vendorResponse[fieldName] !== undefined &&
        priceItem.vendorResponse[fieldName] !== null
    ) {
        return priceItem.vendorResponse[fieldName];
    }

    if (priceItem.isHeaderRow) {
        if (fieldName === LINE_ITEM) {
            return priceItem.description;
        }
        return '';
    }

    return priceItem[fieldName];
};

export const calculatePriceTableTotals = (priceTable, salesTax) => {
    let totalCost = null;
    let salesTaxCost = null;

    totalCost = priceTable.priceItems.reduce((total, priceItem) => {
        const priceItemTotal =
            calculatePriceItemTotalCost(priceItem, priceTable.percentageAdjustmentType) || 0;
        total += priceItemTotal;
        return total;
    }, 0);

    if (salesTax && priceTable.hasSalesTaxRow) {
        salesTaxCost = priceTable.priceItems.reduce((total, priceItem) => {
            const priceItemSalesTax = priceItem.taxable
                ? calculatePriceItemTotalCost(priceItem, priceTable.percentageAdjustmentType) *
                      (salesTax / 100) || 0
                : 0;
            total += priceItemSalesTax;
            return total;
        }, 0);

        totalCost += salesTaxCost;
    }

    return {
        salesTaxCost,
        totalCost,
    };
};

/**
 * Generates an array of vendor data and price tables with line item awards by vendor
 *
 * @param {array} proposals The vendor proposals
 * @param {array} lineItemAwardsData The award data for each price table
 * @return {array} Line item awards by vendor
 */
export const generateVendorAwardData = (proposals, lineItemAwardsData) => {
    const vendorAwardData = [];

    proposals.forEach((proposal, i) => {
        const vendor = { ...proposal, priceTables: [] };

        (lineItemAwardsData || []).forEach((lineItemAward, j) => {
            vendor.priceTables.push({ ...lineItemAward.priceTable, awardRows: [] });

            (lineItemAward.rows || []).forEach((row) => {
                if (row.vendorResponses[i] && row.vendorResponses[i].awardType) {
                    vendor.priceTables[j].awardRows.push({
                        awardId: row.vendorResponses[i].lineItemAwardId,
                        awardType: row.vendorResponses[i].awardType,
                        comment: row.vendorResponses[i].comment,
                        custom1: row.vendorResponses[i].custom1,
                        custom2: row.vendorResponses[i].custom2,
                        custom3: row.vendorResponses[i].custom3,
                        custom4: row.vendorResponses[i].custom4,
                        custom5: row.vendorResponses[i].custom5,
                        description: row.description,
                        discount: row.vendorResponses[i].discount,
                        discountOnly: row.discountOnly,
                        isHeaderRow: row.isHeaderRow,
                        lineItem: row.lineItem,
                        quantity: row.vendorResponses[i].quantity,
                        taxable: row.taxable,
                        unitToMeasure: row.unitToMeasure,
                        unitPrice: row.vendorResponses[i].unitPrice,
                    });
                }
            });
        });
        if (vendor.priceTables.some((priceTable) => priceTable.awardRows.length)) {
            vendorAwardData.push(vendor);
        }
    });
    return vendorAwardData;
};
