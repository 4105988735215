import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import { DialogContent, DialogContentText, ReduxFormSelect } from '@og-pro/ui';

import { formConfig } from './constants';

const TriageApprovalModalForm = ({ handleSubmit, nextApprovers }) => {
    return (
        <form onSubmit={handleSubmit}>
            <DialogContent sx={{ pt: 1, pb: 1.5 }}>
                <DialogContentText sx={{ p: 0, mb: 2 }}>
                    Before you approve, the next step requires you to pick who needs to review.
                </DialogContentText>
                <Field
                    component={ReduxFormSelect}
                    fullWidth
                    label="Select Next Primary Reviewer"
                    name="selectedApprover"
                    options={nextApprovers}
                    required
                />
                <DialogContentText sx={{ mt: 3, p: 0 }}>
                    Ready to approve and assign this request?
                </DialogContentText>
            </DialogContent>
        </form>
    );
};

TriageApprovalModalForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    nextApprovers: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
        })
    ).isRequired,
};

export const ReduxTriageApprovalModalForm = reduxForm({
    form: formConfig,
})(TriageApprovalModalForm);
