import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 } from 'uuid';

import { Typography, Box, Button } from '@og-pro/ui';
import AddIcon from '@mui/icons-material/Add';

import { AiImporterDropzone } from './ContractInsuranceImporter/AiImporterDropzone';
import { AiImporterLoading } from './ContractInsuranceImporter/AiImporterLoading';
import { uploadFileToGenerativeAi } from '../../../actions/templatesAdmin';
import { EmptyInsuranceTable } from './ContractInsurancesTable/EmptyInsuranceTable';
import { ContractInsurancesTable } from './ContractInsurancesTable';
import { EditInsurancesDetailModal } from './ContractInsuranceDetailModal';

import { fieldNamesDict } from './ContractInsurancesForm/constants';
import { getTagsManagementPath, isContractEditor } from '../selectors';
import { getContractJS, isSystemAdminUser } from '../../selectors';
import { updateContractInsurances } from '../../../actions/contracts';

const { CONTRACT_INSURANCES } = fieldNamesDict;

const mapStateToProps = (state) => {
    return {
        contract: getContractJS(state),
        isEditor: isContractEditor(state),
        isSystemAdmin: isSystemAdminUser(state),
        managingInsurances: state.contracts.get('managingInsurances'),
        tagsManagementPath: getTagsManagementPath(state),
    };
};
const mapDispatchToProps = (dispatch) => ({
    updateContractInsurances: (id, data) => dispatch(updateContractInsurances(id, data)),
    uploadFileToAi: (governmentId, file) => dispatch(uploadFileToGenerativeAi(governmentId, file)),
});
// @connect
class ConnectedContractInsurances extends Component {
    static propTypes = {
        contract: PropTypes.shape({
            id: PropTypes.number.isRequired,
            government_id: PropTypes.number.isRequired,
        }).isRequired,

        updateContractInsurances: PropTypes.func.isRequired,
        uploadFileToAi: PropTypes.func.isRequired,
    };

    state = {
        loading: false,
        loadingError: null,
        filename: '',
        templateData: [],
        disabled: false,
        isModalOpen: false,
        currentEntry: null,
    };

    handleSubmit = (data) => {
        const { contract } = this.props;

        if (!data || !data[CONTRACT_INSURANCES]) {
            return;
        }

        this.props.updateContractInsurances(contract.id, data[CONTRACT_INSURANCES]);
    };

    handleOpenModal = (entry = null) => {
        this.setState({ isModalOpen: true, currentEntry: entry });
    };

    handleCloseModal = () => {
        this.setState({ isModalOpen: false, currentEntry: null });
    };

    handleSaveInsurance = (insurance) => {
        const { templateData, currentEntry } = this.state;
        if (currentEntry) {
            const updatedData = templateData.map((item) =>
                item === currentEntry ? { ...item, ...insurance } : item
            );
            this.setState({ templateData: updatedData });
        } else {
            const newInsurance = { ...insurance, uuid: v4() };
            this.setState({ templateData: [...templateData, newInsurance] });
        }
    };

    handleDeleteInsurance = (insurance) => {
        const { templateData } = this.state;
        const updatedData = templateData.filter((item) => item !== insurance);
        this.setState({ templateData: updatedData });
    };

    handleDrop = async (files) => {
        const { contract, uploadFileToAi } = this.props;
        const file = files[0];
        this.setState({ loading: true, loadingError: null, filename: file.name });

        try {
            const response = await uploadFileToAi(contract.government_id, file);
            console.log(`ContractInsurances.handleDrop() response:`, response);
            const sections = response?.data.map((a) => ({ ...a, uuid: v4() }));

            if (!sections) {
                throw new Error('Invalid response format');
            }

            this.setState({ templateData: sections });
        } catch (error) {
            this.setState({ loadingError: error?.message || 'An error occurred' });
        } finally {
            this.setState({ loading: false });
        }
    };

    handleFileInputChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            this.handleDrop(files);
        }
    };

    triggerFileInput = () => {
        this.fileInput.click();
    };

    render() {
        const {
            loading,
            loadingError,
            filename,
            templateData,
            disabled,
            isModalOpen,
            currentEntry,
        } = this.state;

        return (
            <Box sx={{ px: 12, py: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}
                >
                    <Typography variant="h4">Insurance</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                        <Button
                            color="secondary"
                            onClick={() => this.handleOpenModal()}
                            startIcon={<AddIcon />}
                            variant="text"
                        >
                            Add Insurance Manually
                        </Button>
                        <Button color="primary" onClick={this.triggerFileInput} variant="contained">
                            Upload Insurance Document
                        </Button>
                        <input
                            onChange={this.handleFileInputChange}
                            ref={(ref) => {
                                this.fileInput = ref;
                            }}
                            style={{ display: 'none' }}
                            type="file"
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        border: '1px solid',
                        borderColor: 'grey.200',
                        borderRadius: '6px',
                        p: 2,
                        mt: 2,
                    }}
                >
                    {loading ? (
                        <AiImporterLoading filename={filename} />
                    ) : (
                        <Box>
                            <AiImporterDropzone
                                disabled={disabled}
                                onDropAccepted={this.handleDrop}
                            />
                            {loadingError && (
                                <Box
                                    component={Typography}
                                    sx={(theme) => ({
                                        color: theme.palette.error.main,
                                        paddingTop: 1,
                                    })}
                                >
                                    {loadingError}
                                </Box>
                            )}
                        </Box>
                    )}
                    {!loading &&
                        (templateData.length === 0 ? (
                            <EmptyInsuranceTable />
                        ) : (
                            <ContractInsurancesTable
                                handleDeleteInsurance={this.handleDeleteInsurance}
                                handleOpenModal={this.handleOpenModal}
                                rows={templateData}
                            />
                        ))}
                </Box>

                <EditInsurancesDetailModal
                    data={currentEntry}
                    handleClose={this.handleCloseModal}
                    onSave={this.handleSaveInsurance}
                    open={isModalOpen}
                />
            </Box>
        );
    }
}

export const ContractInsurances = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedContractInsurances);
