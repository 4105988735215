const { percentageAdjustmentTypesDict } = require('.');

const { DISCOUNT, MARKUP } = percentageAdjustmentTypesDict;

/**
 * Given a price item, calculate the total cost of the item, if possible.
 *
 * @param {PriceItem} priceItem The price item to attempt to calculate the value for
 * @param {string} percentageAdjustmentType The type of percentage adjustment to apply, defaults to discount
 * @return {number|null} The total price if it can be calculated, `null` otherwise
 */
exports.calculatePriceItemTotalCost = (priceItem, percentageAdjustmentType = DISCOUNT) => {
    const { discount, quantity, unitPrice, vendorResponse } = priceItem;

    if ((!quantity || !unitPrice) && !vendorResponse) {
        return null;
    }

    if (vendorResponse && vendorResponse.noBid) {
        return null;
    }

    const numericQuantity = Number.parseFloat(quantity || vendorResponse.quantity);
    const numericUnitPrice = Number.parseFloat(unitPrice || vendorResponse.unitPrice);
    const rawDiscount = Number.parseFloat(discount || (vendorResponse && vendorResponse.discount));
    const numericDiscount = Number.isNaN(rawDiscount) ? 0 : rawDiscount / 100;

    if (Number.isNaN(numericQuantity) || Number.isNaN(numericUnitPrice)) {
        return null;
    }

    if (percentageAdjustmentType === MARKUP) {
        return numericQuantity * numericUnitPrice * (1 + numericDiscount);
    }

    return numericQuantity * numericUnitPrice * (1 - numericDiscount);
};
