import { fromJS } from 'immutable';

import {
    GENERATE_AI_CHART_CONFIG,
    GENERATE_AI_CHART_CONFIG_FAIL,
    GENERATE_AI_CHART_CONFIG_SUCCESS,
} from '../actions/dashboardAiChart';

const initialState = fromJS({
    chartConfig: null,
    loading: false,
    loadError: null,
});

export default function dashboardAiChartReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GENERATE_AI_CHART_CONFIG:
            return state.merge(
                fromJS({
                    chartConfig: null,
                    loading: true,
                    loadError: null,
                })
            );
        case GENERATE_AI_CHART_CONFIG_SUCCESS:
            return state.merge(
                fromJS({
                    chartConfig: fromJS(JSON.parse(action.result.generativeAiChat.content)),
                    loading: false,
                    loadError: null,
                })
            );
        case GENERATE_AI_CHART_CONFIG_FAIL:
            return state.merge(
                fromJS({
                    loading: false,
                    loadError: action.error && action.error.message,
                })
            );
        default:
            return state;
    }
}
