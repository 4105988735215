import { browserHistory } from '@og-pro-migration-tools/react-router';
import moment from 'moment-timezone';
import { change } from 'redux-form';

import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import { createMockRequisition } from '@og-pro/shared-config/requisitions/mocks';

import { showSnackbar } from './notification';
import { emitUpdateAction } from './requisitionSocket';
import { downloadCSV, resourceManager } from '../helpers';
import { emitRequisitionSocket, putToS3 } from './utils';
import { formConfig } from '../containers/Requisitions/RequisitionsCreate/RequisitionsCreateForms/form';
import {
    hideMissingVendorAddressBanner,
    hideMissingVendorBanner,
    loadRequisitionLogs,
} from './requisitionsApprovals';

export const LOAD_REQUISITION = 'requisitions/LOAD_REQUISITION';
export const LOAD_REQUISITION_SUCCESS = 'requisitions/LOAD_REQUISITION_SUCCESS';
export const LOAD_REQUISITION_FAILURE = 'requisitions/LOAD_REQUISITION_FAILURE';

export const LOAD_REQUISITION_STEPS = 'requisitions/LOAD_REQUISITION_STEPS';
export const LOAD_REQUISITION_STEPS_SUCCESS = 'requisitions/LOAD_REQUISITION_STEPS_SUCCESS';
export const LOAD_REQUISITION_STEPS_FAILURE = 'requisitions/LOAD_REQUISITION_STEPS_FAILURE';

export const LOAD_BUDGET_CHECK = 'requisitions/LOAD_BUDGET_CHECK';
export const LOAD_BUDGET_CHECK_SUCCESS = 'requisitions/LOAD_BUDGET_CHECK_SUCCESS';
export const LOAD_BUDGET_CHECK_FAILURE = 'requisitions/LOAD_BUDGET_CHECK_FAILURE';
export const UPDATE_BUDGET_INFO = 'requisitions/UPDATE_BUDGET_INFO';
export const RESET_REQUISITION = 'requisitions/RESET_REQUISITION';

export const CREATE_REQUISITION = 'requisitions/CREATE_REQUISITION';
export const CREATE_REQUISITION_SUCCESS = 'requisitions/CREATE_REQUISITION_SUCCESS';
export const CREATE_REQUISITION_FAILURE = 'requisitions/CREATE_REQUISITION_FAILURE';

export const UPDATE_REQUISITION = 'requisitions/UPDATE_REQUISITION';
export const UPDATE_REQUISITION_SUCCESS = 'requisitions/UPDATE_REQUISITION_SUCCESS';
export const UPDATE_REQUISITION_FAILURE = 'requisitions/UPDATE_REQUISITION_FAILURE';

export const DELETE_REQUISITION = 'requisitions/DELETE_REQUISITION';
export const DELETE_REQUISITION_SUCCESS = 'requisitions/DELETE_REQUISITION_SUCCESS';
export const DELETE_REQUISITION_FAILURE = 'requisitions/DELETE_REQUISITION_FAILURE';

export const COPY_REQUISITION = 'requisitions/COPY_REQUISITION';
export const COPY_REQUISITION_SUCCESS = 'requisitions/COPY_REQUISITION_SUCCESS';
export const COPY_REQUISITION_FAILURE = 'requisitions/COPY_REQUISITION_FAILURE';

export const SHOW_VENDOR_ASSIGNMENT_MODAL = 'requisitions/SHOW_VENDOR_ASSIGNMENT_MODAL';
export const HIDE_VENDOR_ASSIGNMENT_MODAL = 'requisitions/HIDE_VENDOR_ASSIGNMENT_MODAL';

export const SHOW_BUDGET_CHECK_ALERT_MODAL = 'requisitions/SHOW_BUDGET_CHECK_ALERT_MODAL';
export const HIDE_BUDGET_CHECK_ALERT_MODAL = 'requisitions/HIDE_BUDGET_CHECK_ALERT_MODAL';

export const CLEAN_BUDGET_CHECK_RESULTS = 'requisitions/CLEAN_BUDGET_CHECK_RESULTS';

export const SHOW_APPROVAL_MODAL = 'requisitions/SHOW_APPROVAL_MODAL';
export const HIDE_APPROVAL_MODAL = 'requisitions/HIDE_APPROVAL_MODAL';

export const SHOW_TRIAGE_APPROVAL_MODAL = 'requisitions/SHOW_TRIAGE_APPROVAL_MODAL';
export const HIDE_TRIAGE_APPROVAL_MODAL = 'requisitions/HIDE_TRIAGE_APPROVAL_MODAL';

export const UPDATE_IS_OVER_BUDGET = 'requisitions/UPDATE_IS_OVER_BUDGET';

export const resetRequisition = () => {
    return { type: RESET_REQUISITION };
};

export const shouldLoadRequisition = (state, requisitionId) => {
    return (
        !!requisitionId &&
        !state.requisitions.get('loadingRequisition') &&
        requisitionId !== state.requisitions.getIn(['requisition', 'id'])
    );
};

export const loadRequisition = (id) => {
    return resourceManager({
        method: 'get',
        url: `/requisitions/requisitions/${id}`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_REQUISITION }),
        onSuccess: ({ dispatch, result }) => dispatch({ type: LOAD_REQUISITION_SUCCESS, result }),
        onFailure: ({ dispatch, error }) => dispatch({ type: LOAD_REQUISITION_FAILURE, error }),
    });
};

export const loadMockRequisition = (dataValues) => {
    const mockRequisition = createMockRequisition(dataValues);
    return { type: LOAD_REQUISITION_SUCCESS, result: mockRequisition };
};

export const loadReviewSequenceSteps = (id) => {
    return resourceManager({
        method: 'get',
        url: `/requisitions/requisitions/${id}/steps`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_REQUISITION_STEPS }),
        onSuccess: ({ dispatch, result }) =>
            dispatch({ type: LOAD_REQUISITION_STEPS_SUCCESS, result }),
        onFailure: ({ dispatch, error }) =>
            dispatch({ type: LOAD_REQUISITION_STEPS_FAILURE, error }),
    });
};

export const budgetCheckRequisition = (id) => {
    return resourceManager({
        method: 'post',
        url: `/requisitions/requisitions/${id}/budget-check`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_BUDGET_CHECK }),
        onSuccess: ({ dispatch, result }) => {
            const { hasIsOverBudgetChanged, isOverBudget, changedAccountSplitPriceItems } = result;
            let budgetUpdateMessage;
            if (hasIsOverBudgetChanged) {
                budgetUpdateMessage = `Budget status changed: ${
                    isOverBudget ? 'Over Budget' : 'Budget Available'
                }`;
                dispatch(showSnackbar(budgetUpdateMessage));
            }
            const action = { type: UPDATE_IS_OVER_BUDGET, result: { isOverBudget } };
            dispatch(emitRequisitionSocket(id, action, budgetUpdateMessage));
            dispatch({ type: LOAD_BUDGET_CHECK_SUCCESS, result });
            if (changedAccountSplitPriceItems && changedAccountSplitPriceItems.length > 0) {
                dispatch(
                    emitRequisitionSocket(id, {
                        type: UPDATE_BUDGET_INFO,
                        result: {
                            changedAccountSplitPriceItems,
                        },
                    })
                );
            }
        },
        onFailure: ({ dispatch, error }) => {
            dispatch({ type: LOAD_BUDGET_CHECK_FAILURE, error });
        },
    });
};

const saveRequisitionHelper =
    (saveApiPath) =>
    (id, data, opts = {}) => {
        const { budgetCheckAfterSave, onSuccess, successMessage = 'Request saved' } = opts;
        return resourceManager({
            method: 'put',
            url: `/requisitions/requisitions/${id}${saveApiPath}`,
            requestOptions: { data },
            onStart: ({ dispatch }) => {
                dispatch({ type: UPDATE_REQUISITION });
                if ((data?.vendors ?? []).length > 0) {
                    dispatch(hideMissingVendorBanner());
                }
                if (
                    data?.vendors?.every(
                        (vendor) => vendor.address1 && vendor.state && vendor.city && vendor.zipCode
                    )
                ) {
                    dispatch(hideMissingVendorAddressBanner());
                }
            },
            onSuccess: ({ dispatch, result }) => {
                emitUpdateAction(dispatch, result, successMessage);
                dispatch(loadRequisitionLogs(id));
                if (budgetCheckAfterSave) {
                    dispatch(budgetCheckRequisition(id));
                }
                if (onSuccess) {
                    return onSuccess(result);
                }
            },
            onFailure: ({ dispatch, error }) => {
                dispatch({ type: UPDATE_REQUISITION_FAILURE, error });
                dispatch(showSnackbar(`Save Failed: ${error.message}`, { isError: true }));
            },
        });
    };

export const updateRequisition = saveRequisitionHelper('/save');
export const saveGeneralSection = saveRequisitionHelper('/save/general');
export const saveVendorSection = saveRequisitionHelper('/save/vendors');
export const savePricingSection = saveRequisitionHelper('/save/price-tables');
export const saveCustomFormSection = saveRequisitionHelper('/save/custom-form');
export const saveAdditionalSection = saveRequisitionHelper('/save/flag-responses');
export const saveAttachmentsSection = saveRequisitionHelper('/save/attachments');

export const submitRequisition = (id, data, opts = {}) => {
    const { onSuccess } = opts;
    return resourceManager({
        method: 'post',
        url: `/requisitions/requisitions/${id}/actions/submit`,
        requestOptions: { data },
        onStart: ({ dispatch }) => dispatch({ type: UPDATE_REQUISITION }),
        onSuccess: ({ dispatch, result }) => {
            emitUpdateAction(dispatch, result, 'Request submitted');
            if (onSuccess) {
                return onSuccess(result);
            }
        },
        onFailure: ({ dispatch, error }) => {
            dispatch({ type: UPDATE_REQUISITION_FAILURE, error });
            dispatch(showSnackbar(`Submit Failed: ${error.message}`, { isError: true }));
            return error;
        },
    });
};

export const cancelRequisition = (id) => {
    return resourceManager({
        method: 'post',
        url: `/requisitions/requisitions/${id}/actions/cancel`,
        onStart: ({ dispatch }) => dispatch({ type: UPDATE_REQUISITION }),
        onSuccess: ({ dispatch, result }) => {
            emitUpdateAction(dispatch, result, 'Request cancelled');
        },
        onFailure: ({ dispatch, error }) => {
            dispatch({ type: UPDATE_REQUISITION_FAILURE, error });
            dispatch(showSnackbar(`Cancel Failed: ${error.message}`, { isError: true }));
        },
    });
};

export const deleteRequisition = (id) => {
    return resourceManager({
        method: 'del',
        url: `/requisitions/requisitions/${id}`,
        onStart: ({ dispatch }) => dispatch({ type: DELETE_REQUISITION }),
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: DELETE_REQUISITION_SUCCESS, result });
            dispatch(showSnackbar('Request deleted'));
            browserHistory.push(`/governments/${result.government_id}/requisitions`);
        },
        onFailure: ({ dispatch, error }) => dispatch({ type: DELETE_REQUISITION_FAILURE, error }),
    });
};

export const copyRequisition = (id) => {
    return resourceManager({
        method: 'post',
        url: `/requisitions/requisitions/${id}/copy`,
        onStart: ({ dispatch }) => dispatch({ type: COPY_REQUISITION }),
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: COPY_REQUISITION_SUCCESS, result });
            dispatch(showSnackbar('Request copied!'));
            browserHistory.push(
                `/governments/${result.government_id}/requisitions/${result.id}/edit`
            );
        },
        onFailure: ({ dispatch, error }) => dispatch({ type: COPY_REQUISITION_FAILURE, error }),
    });
};

export function downloadRequisitionAuditCSVFile(id, identifier, timezone) {
    const currentDate = moment.tz(timezone);
    const formattedDate = currentDate.format('YYYYMMDDHHmmss');
    const fileName = `requisition-audit-${identifier}-${formattedDate}.csv`;

    return resourceManager({
        method: 'get',
        url: `/requisitions/requisitions/exports/${id}/audit`,
        onSuccess: ({ result }) => {
            downloadCSV(result, fileName);
        },
        onFailure: ({ dispatch, error }) => {
            dispatch(
                showSnackbar(`Audit history export failed: ${error.message}`, { isError: true })
            );
        },
    });
}

export function downloadRequisitionsListCSVFile(data, timezone) {
    const currentDate = moment.tz(timezone);
    const formattedDate = currentDate.format('YYYYMMDDHHmmss');
    const fileName = `requisitions-${formattedDate}.csv`;

    return resourceManager({
        method: 'post',
        url: `/requisitions/requisitions/exports/list`,
        requestOptions: { data },
        onSuccess: ({ result }) => {
            downloadCSV(result, fileName);
        },
        onFailure: ({ dispatch, error }) => {
            dispatch(
                showSnackbar(`Requisitions list export failed: ${error.message}`, { isError: true })
            );
        },
    });
}

export const toggleRequisitionOnHold = (id, isOnHold) => {
    return resourceManager({
        method: 'post',
        url: `/requisitions/requisitions/${id}/actions/on-hold`,
        requestOptions: { data: { isOnHold } },
        onStart: ({ dispatch }) => dispatch({ type: UPDATE_REQUISITION }),
        onSuccess: ({ dispatch, result }) => {
            const onHoldMessage = `Request ${isOnHold ? 'put on hold' : 'taken off hold'}`;
            emitUpdateAction(dispatch, result, onHoldMessage);
        },
        onFailure: ({ dispatch, error }) => {
            dispatch({ type: UPDATE_REQUISITION_FAILURE, error });
            dispatch(showSnackbar(`On hold update failed: ${error.message}`, { isError: true }));
        },
    });
};

export const showVendorAssignmentModal = (data) => {
    return { type: SHOW_VENDOR_ASSIGNMENT_MODAL, data };
};

export const hideVendorAssignmentModal = () => {
    return { type: HIDE_VENDOR_ASSIGNMENT_MODAL };
};

const showRequisitionBudgetCheckAlertModal = (onContinue, onCancel, modalData) => {
    return {
        type: SHOW_BUDGET_CHECK_ALERT_MODAL,
        onContinue,
        onCancel,
        modalData,
    };
};

export const cleanRequisitionBudgetCheckResults = () => {
    return { type: CLEAN_BUDGET_CHECK_RESULTS };
};

export const hideRequisitionBudgetCheckAlertModal = () => {
    return { type: HIDE_BUDGET_CHECK_ALERT_MODAL };
};

export const showRequisitionApprovalModal = (onConfirm, data) => {
    return { type: SHOW_APPROVAL_MODAL, onConfirm, data };
};

export const showRequisitionTriageApprovalModal = (onConfirm, data) => {
    return { type: SHOW_TRIAGE_APPROVAL_MODAL, onConfirm, data };
};

export const hideRequisitionTriageApprovalModal = () => {
    return { type: HIDE_TRIAGE_APPROVAL_MODAL };
};

export const hideRequisitionApprovalModal = () => {
    return { type: HIDE_APPROVAL_MODAL };
};

export const saveBudgetStatus = (id, isOverBudget) => {
    return resourceManager({
        method: 'put',
        url: `/requisitions/requisitions/${id}/save/budget-status`,
        requestOptions: { data: { isOverBudget } },
        onSuccess: ({ dispatch, result }) => {
            if (result.hasChanged) {
                dispatch({ type: UPDATE_IS_OVER_BUDGET, result });
            }
        },
        onFailure: ({ dispatch, error }) => {
            dispatch(showSnackbar(`Budget update failed: ${error.message}`, { isError: true }));
        },
    });
};

export const preCheckBudget = (requisitionId, actionLabel, next, cancel) => {
    return (dispatch) => {
        dispatch(budgetCheckRequisition(requisitionId));
        dispatch(showRequisitionBudgetCheckAlertModal(next, cancel, { actionLabel }));
    };
};

const uploadAttachmentHandler = ({ context, dispatch, result }) => {
    const { fieldName, attachments, title, file } = context;
    return putToS3({ context, result })
        .then((data) => {
            const { path, filename, bucket } = data;
            const attachmentData = {
                bucket,
                path,
                filename,
                type: attachmentTypesDict.REQUISITION_ATTACHMENT,
                title,
                url: result.signedGetUrl,
                file,
            };
            dispatch(change(formConfig.form, fieldName, [...(attachments || []), attachmentData]));
            dispatch(showSnackbar('Attachment uploaded successfully!'));
        })
        .catch((error) => {
            dispatch(
                showSnackbar(`Attachment uploading failed: ${error?.message}`, { isError: true })
            );
        });
};

/*
 * Gets the signed URL from the server and then uploads the file to S3 using the signed URL
 * @param {Object} file - The file to upload
 * @param {Object} title - The title of the attachment
 * @param {Object} requisitionId - The requisition id
 * @param {Object} vendor - The vendor object
 * @param {Function} onProgress - The function to call when the progress changes
 * @returns {Promise} - The promise from the resourceManager
 */
export const uploadAttachment = (
    file,
    { title, requisitionId, vendorAssignmentUuid, fieldName, attachments },
    onProgress
) => {
    return resourceManager({
        method: 'get',
        url: `/requisitions/requisitions/${requisitionId}/s3`,
        requestOptions: ({ context }) => {
            return {
                params: {
                    filename: context.file.name,
                    contentType: context.file.type,
                },
            };
        },
        onSuccess: uploadAttachmentHandler,
        context: {
            file,
            title,
            onProgress,
            requisitionId,
            vendorAssignmentUuid,
            fieldName,
            attachments,
        },
    });
};

export const searchRequisitionVendors = (requisitionId, vendorIds) => {
    return resourceManager({
        method: 'post',
        url: `/requisitions/requisitions/${requisitionId}/vendors/search`,
        requestOptions: { data: { vendorIds } },
    });
};
