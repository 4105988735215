import {
    Add as AddIcon,
    TaskAlt as TaskAltIcon,
    Refresh as RefreshIcon,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent, Typography } from '@og-pro/ui';
import React from 'react';

import { ZeroState } from '../../../../../../../../components';

export const AiContractInsurances = ({ contractInsurances, disabled, onCancel, removeSection }) => {
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 1 }}>
                <Box>
                    <Typography>Suggested Contract Insurances</Typography>
                </Box>
                <Box>
                    <Button
                        disabled={disabled}
                        onClick={onCancel}
                        qaTag="aiImporter-restart-import"
                        startIcon={<RefreshIcon />}
                    >
                        Restart Import
                    </Button>
                </Box>
            </Box>
            {contractInsurances.length === 0 && (
                <Box
                    component={ZeroState}
                    info="Upload another document"
                    title={
                        <>
                            <TaskAltIcon fontSize="inherit" /> No more suggested insurances!
                        </>
                    }
                    useOpenGovStyle
                />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {contractInsurances.map((contractInsurance) => {
                    return (
                        <Card key={contractInsurance.name}>
                            <CardContent>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Box>
                                        <Button
                                            onClick={() => removeSection(contractInsurance.uuid)}
                                            qaTag="aiImporter-add-tag"
                                            startIcon={<AddIcon />}
                                        >
                                            Add Insurance
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexGrow: 1,
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Box>
                                            <Typography>
                                                {contractInsurance.insuranceType}
                                            </Typography>
                                            <Typography variant="bodyXSmall">
                                                Insurance Type
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography>
                                                {contractInsurance.expirationDate}
                                            </Typography>
                                            <Typography variant="bodyXSmall">
                                                Expiration Date
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        </Box>
    );
};

AiContractInsurances.propTypes = {
    contractInsurances: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    removeSection: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
