import { Box, Typography } from '@og-pro/ui';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';

import { AiContractInsurances } from './AiContractInsurances';
import { AiImporterDropzone } from './AiImporterDropzone';
import { AiImporterLoading } from './AiImporterLoading';
import { TemplateEditV2FunctionsContext } from '../../../context';
import {
    importProjectSection,
    uploadFileToGenerativeAi,
} from '../../../../../../../actions/templatesAdmin';
import { SectionHeaderViewTitleOnly } from '../../../../../../../components/SDv2';

export const TemplateEditV2AiImporter = () => {
    const { disabled, projectId, reloadTemplate, templateProject } = useContext(
        TemplateEditV2FunctionsContext
    );

    const [templateData, setTemplateData] = useState(null);
    const [filename, setFilename] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(null);
    const [shouldReloadTemplateOnUnmount, setShouldReloadTemplateOnUnmount] = useState(false);

    const dispatch = useDispatch();

    const handleDrop = async (files) => {
        const file = files[0];
        setLoading(true);
        setLoadingError(null);
        setFilename(file.name);

        try {
            const response = await dispatch(
                uploadFileToGenerativeAi(templateProject.government_id, file)
            );
            const contractInsurances = response?.data.map((contractInsurance) => ({
                ...contractInsurance,
                uuid: v4(),
            }));

            if (!contractInsurances) {
                throw new Error('Invalid response format');
            }

            setTemplateData(contractInsurances);
        } catch (error) {
            setLoadingError(error?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const removeSection = (uuid) => {
        setTemplateData(templateData.filter((section) => section.uuid !== uuid));
    };

    const importSection = async (uuid, data) => {
        await dispatch(importProjectSection(projectId, data));
        removeSection(uuid);
        setShouldReloadTemplateOnUnmount(true);
    };

    useEffect(() => {
        return () => {
            if (shouldReloadTemplateOnUnmount) {
                reloadTemplate();
            }
        };
    }, [shouldReloadTemplateOnUnmount, reloadTemplate]);

    const styles = require('./index.scss');

    return (
        <Box className={styles.container}>
            <Box className={styles.header}>
                <SectionHeaderViewTitleOnly
                    description="Upload a template document file and let AI ingest the contents into the template"
                    title="AI Importer"
                />
            </Box>
            <Box className={styles.content}>
                <Box className={styles.contentBox}>
                    <div className={styles.heading}>
                        <Typography variant="h3">Import Template File</Typography>
                        <Typography>
                            For best results, ensure the Word document is formatted with proper
                            hierarchal tags (Heading 1, Heading 2, etc).
                        </Typography>
                        <Typography>
                            Documents over 60 pages may need to be split into multiple documents.
                        </Typography>
                    </div>
                    {loading && <AiImporterLoading filename={filename} />}
                    {!loading && templateData === null && (
                        <>
                            <AiImporterDropzone disabled={disabled} onDropAccepted={handleDrop} />
                            {loadingError && (
                                <Box
                                    component={Typography}
                                    sx={(theme) => ({
                                        color: theme.palette.error.main,
                                        paddingTop: 1,
                                    })}
                                >
                                    {loadingError}
                                </Box>
                            )}
                        </>
                    )}
                    {templateData && (
                        <AiContractInsurances
                            contractInsurances={templateData}
                            disabled={disabled}
                            filename={filename}
                            importSection={importSection}
                            onCancel={() => setTemplateData(null)}
                            removeSection={removeSection}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
};
