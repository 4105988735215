import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, IconButton, ReduxFormTextField, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import {
    desiredDeliveryDateOptionValues,
    isRequisitionDraft,
} from '@og-pro/shared-config/requisitions';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import {
    FIN_DESCRIPTION_OF_REQUEST_MAX_LENGTH,
    PRO_DESCRIPTION_OF_REQUEST_MAX_LENGTH,
} from './constants';
import { ExceptionSequenceSelect } from './ExceptionSequenceSelect';
import { getExceptionSequenceOptions } from './selectors';
import { fieldNames } from '../../constants';
import { formConfig } from '../../form';
import { getInvitedUsersSelectOptions } from '../../../../../selectors';
import {
    DateTimePicker,
    ExpectedPurchaseOrderDateField,
    InputText,
    SearchSelect,
    SearchSelectUserOption,
    UserProfilePicture,
} from '../../../../../../components';
import { limitTextLength } from '../../../../../../Forms/normalizers';
import {
    getRequisitionEndsInPurchaseOrder,
    getRequisitionJS,
} from '../../../../../../selectors/govApp';
import { useFiscalPeriodFormDisplay } from '../../../../../../lib/ogFinancials';
import { CategorySelectInput } from '../../../../../CategorySelect';
import { CategoryCodesChipList } from './CategoryCodesChipList';
import { CategoryCodesHeader } from './CategoryCodesHeader';
import { getRequisitionFormValues } from '../../../RequisitionsCreate/selectors';

export const GeneralInformation = ({
    disabled,
    isApprovalView,
    saveRequisition,
    showFormValidation,
}) => {
    const styles = require('./index.scss');
    const sharedStyles = require('../../shared.scss');

    const userSelectOptions = useSelector(getInvitedUsersSelectOptions);
    const { creator, requestType, reviewGroup, fiscalPeriodObject, status, background } =
        useSelector(getRequisitionJS);
    const exceptionSequenceOptions = useSelector(getExceptionSequenceOptions);
    const enableMUIComponents = useFlags(FLAGS.ENABLE_GENERAL_INFO_MUI_COMPONENTS);

    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);

    const requestorOptions = useMemo(() => {
        const creatorIdsSet = new Set(reviewGroup.creators.map(({ id }) => id));
        return userSelectOptions.filter(({ value }) => creatorIdsSet.has(value));
    }, [reviewGroup.creators]);

    const {
        openFiscalPeriodsRange,
        fmsFiscalPeriod,
        handleExpectedPurchaseOrderDateChange,
        isError,
        isLoadingFiscalPeriods,
        isLoadingFiscalPeriodsRange,
        fiscalYearSelectOptions,
    } = useFiscalPeriodFormDisplay(
        formConfig.form,
        fieldNames.FISCAL_PERIOD_OBJECT,
        fieldNames.FISCAL_PERIOD,
        fiscalPeriodObject
    );

    const isLoading = isLoadingFiscalPeriodsRange || isLoadingFiscalPeriods;
    const isDraft = isRequisitionDraft(status);
    const [showBackground, setShowBackground] = useState(background?.length > 0);
    const normalizeDescription = limitTextLength(
        endsInPurchaseOrder
            ? FIN_DESCRIPTION_OF_REQUEST_MAX_LENGTH
            : PRO_DESCRIPTION_OF_REQUEST_MAX_LENGTH
    );
    const dispatch = useDispatch();

    const categories = useSelector(getRequisitionFormValues).categories || [];

    useEffect(() => {
        if (!showBackground) {
            dispatch(change(formConfig.form, fieldNames.BACKGROUND, null));
        }
    }, [showBackground, dispatch]);

    return (
        <div className={classNames(styles.generalInformation)}>
            <div>
                <Typography className={styles.heading} variant="h4">
                    What is needed?
                </Typography>
                {enableMUIComponents ? (
                    <>
                        <Field
                            characterLimit={
                                endsInPurchaseOrder
                                    ? FIN_DESCRIPTION_OF_REQUEST_MAX_LENGTH
                                    : undefined
                            }
                            component={ReduxFormTextField}
                            disabled={disabled}
                            fullWidth
                            label={
                                <Typography variant="h5">
                                    Name of Request{requestType.requireDescriptionOfRequest && ' *'}
                                </Typography>
                            }
                            name={fieldNames.DESCRIPTION_OF_REQUEST}
                            normalize={normalizeDescription}
                            qaTag={`${formConfig.form}-${fieldNames.DESCRIPTION_OF_REQUEST}`}
                            showValidation={showFormValidation}
                            sx={{
                                mb: 2,
                            }}
                        />
                        <Field
                            component={ReduxFormTextField}
                            disabled={disabled}
                            fullWidth
                            label={
                                <Typography variant="h5">
                                    Summary of Request{requestType.requireSummary && ' *'}
                                </Typography>
                            }
                            minRows={4}
                            multiline
                            name={fieldNames.SUMMARY}
                            qaTag={`${formConfig.form}-${fieldNames.SUMMARY}`}
                            rows={null}
                            showValidation={showFormValidation}
                            sx={{
                                mb: 2,
                            }}
                        />
                    </>
                ) : (
                    <>
                        <Field
                            characterLimit={
                                endsInPurchaseOrder
                                    ? FIN_DESCRIPTION_OF_REQUEST_MAX_LENGTH
                                    : undefined
                            }
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            label={
                                <Typography variant="h5">
                                    Name of Request{requestType.requireDescriptionOfRequest && ' *'}
                                </Typography>
                            }
                            name={fieldNames.DESCRIPTION_OF_REQUEST}
                            normalize={normalizeDescription}
                            qaTag={`${formConfig.form}-${fieldNames.DESCRIPTION_OF_REQUEST}`}
                            showValidation={showFormValidation}
                            useOpenGovStyle
                        />
                        <Field
                            component={InputText}
                            disabled={disabled}
                            label={
                                <Typography variant="h5">
                                    Summary of Request{requestType.requireSummary && ' *'}
                                </Typography>
                            }
                            name={fieldNames.SUMMARY}
                            showValidation={showFormValidation}
                            type="textarea"
                            useOpenGovStyle
                        />
                    </>
                )}
                <Box display="flex" flexDirection={categories.length > 0 ? 'column' : 'row'}>
                    {requestType.showBackground && !showBackground && (
                        <Box mb={2}>
                            <Button
                                color="secondary"
                                disabled={disabled}
                                onClick={() => setShowBackground(true)}
                                qaTag={`${formConfig.form}-showBackground`}
                                startIcon={<AddIcon />}
                                variant="text"
                            >
                                Add Background Information
                            </Button>
                        </Box>
                    )}
                    {requestType.showCategories && (
                        <Field
                            component={CategorySelectInput}
                            components={{
                                CategoryList: CategoryCodesChipList,
                                Header: CategoryCodesHeader,
                            }}
                            disabled={disabled}
                            label="Category Codes"
                            maxLength={110}
                            name={fieldNames.CATEGORIES}
                            onChange={() => {}}
                            useSingleCodeSet
                        />
                    )}
                </Box>

                {showBackground && (
                    <div className={styles.backgroundContainer}>
                        {enableMUIComponents ? (
                            <Field
                                component={ReduxFormTextField}
                                disabled={disabled}
                                fullWidth
                                label={
                                    <Typography variant="h5">
                                        Background &nbsp;
                                        <IconButton
                                            onClick={() => setShowBackground(false)}
                                            qaTag={`${formConfig.form}-hideBackground`}
                                            size="small"
                                            variant="text"
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    </Typography>
                                }
                                minRows={4}
                                multiline
                                name={fieldNames.BACKGROUND}
                                qaTag={`${formConfig.form}-text-background`}
                                rows={null}
                                showValidation={showFormValidation}
                                sx={{
                                    mb: 2,
                                }}
                            />
                        ) : (
                            <Field
                                component={InputText}
                                disabled={disabled}
                                label={
                                    <Typography variant="h5">
                                        Background &nbsp;
                                        <IconButton
                                            onClick={() => setShowBackground(false)}
                                            qaTag={`${formConfig.form}-hideBackground`}
                                            size="small"
                                            variant="text"
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    </Typography>
                                }
                                name={fieldNames.BACKGROUND}
                                qaTag={`${formConfig.form}-add-background`}
                                showValidation={showFormValidation}
                                type="textarea"
                                useOpenGovStyle
                            />
                        )}
                    </div>
                )}
            </div>
            <div>
                <Typography className={styles.heading} variant="h4">
                    Who is making the request, and who needs to approve?
                </Typography>
                <div className="row">
                    <div className="col-xs-12 col-md-4">
                        <Field
                            component={SearchSelect}
                            components={{ Option: SearchSelectUserOption }}
                            disabled={disabled}
                            label={<Typography variant="h5">Requestor *</Typography>}
                            name={fieldNames.REQUESTOR_ID}
                            options={requestorOptions}
                            showValidation={showFormValidation}
                            useOpenGovStyle
                        />
                    </div>
                    <div className={`col-xs-12 col-md-4 ${sharedStyles.formField}`}>
                        <Typography variant="h5">Approval Group *</Typography>
                        <div>{reviewGroup.name}</div>
                    </div>
                    <div className={`col-xs-12 col-md-4 ${sharedStyles.formField}`}>
                        <Typography variant="h5">Creator</Typography>
                        <div className={styles.creator}>
                            <UserProfilePicture user={creator} />
                            <span>{creator.displayName}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h4 className={styles.heading}>When is the purchase needed?</h4>
                <div className={classNames('row', styles.datesContainer)}>
                    {endsInPurchaseOrder && (
                        <div className="col-xs-12 col-md-4">
                            <ExpectedPurchaseOrderDateField
                                className={styles.datePicker}
                                disabled={disabled || !isDraft}
                                hasLoadingError={isError}
                                isLoading={isLoading}
                                label="Expected Purchase Order Date *"
                                name={fieldNames.EXPECTED_PURCHASE_ORDER_DATE}
                                onChange={handleExpectedPurchaseOrderDateChange}
                                openFiscalPeriodsRange={openFiscalPeriodsRange}
                                qaTag={`${formConfig.form}-${fieldNames.EXPECTED_PURCHASE_ORDER_DATE}`}
                                showErrorIcon
                                sx={{
                                    mb: enableMUIComponents ? 2 : 0,
                                }}
                            />
                        </div>
                    )}
                    {requestType.desiredDeliveryDate !== desiredDeliveryDateOptionValues.HIDDEN && (
                        <div className="col-xs-12 col-lg-6">
                            <Field
                                allowEmpty
                                className={styles.datePicker}
                                component={DateTimePicker}
                                disabled={disabled}
                                label={
                                    <Typography variant="h5">
                                        {`Desired Delivery Date${requestType.desiredDeliveryDate === desiredDeliveryDateOptionValues.REQUIRED ? ' *' : ''}`}
                                    </Typography>
                                }
                                labelClassName={styles.fiscalYearLabel}
                                name={fieldNames.DESIRED_DELIVERY_DATE}
                                showValidation={showFormValidation}
                                time={false}
                                useOpenGovStyle
                            />
                        </div>
                    )}
                    {endsInPurchaseOrder ? (
                        <div className={classNames('col-xs-12 col-lg-6', styles.budgetYearText)}>
                            Fiscal Year:{' '}
                            <span className={styles.budgetYearLightText}>{fmsFiscalPeriod}</span>
                        </div>
                    ) : (
                        <div className="col-xs-12 col-lg-6">
                            <Field
                                component={SearchSelect}
                                disabled={disabled}
                                hasFeedback={false}
                                label={<Typography variant="h5">Fiscal Year *</Typography>}
                                name={fieldNames.FISCAL_PERIOD_TAG_ID}
                                options={fiscalYearSelectOptions}
                                placeholder="Choose a Fiscal Year"
                                qaTag={`${formConfig.form}-${fieldNames.FISCAL_PERIOD_TAG_ID}`}
                                showValidation={showFormValidation}
                                useOpenGovStyle
                            />
                        </div>
                    )}
                </div>
            </div>
            {exceptionSequenceOptions.length > 0 && !isApprovalView && (
                <Box
                    borderTop={`1px solid ${capitalDesignTokens.semanticColors.border.primary}`}
                    paddingTop={2.5}
                >
                    <ExceptionSequenceSelect
                        disabled={disabled}
                        options={exceptionSequenceOptions}
                        saveRequisition={saveRequisition}
                        showFormValidation={showFormValidation}
                    />
                </Box>
            )}
        </div>
    );
};

GeneralInformation.propTypes = {
    disabled: PropTypes.bool,
    isApprovalView: PropTypes.bool,
    saveRequisition: PropTypes.func,
    showFormValidation: PropTypes.bool,
};
