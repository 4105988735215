import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { contactFieldNames } from '@og-pro/shared-config/contacts';

import { InputText } from '../../InputText';
import { PhoneNumberInput } from '../../Forms/sections';

const { EMAIL } = contactFieldNames;

export class ContactInfo extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        fieldNames: PropTypes.object.isRequired,
        formName: PropTypes.string.isRequired,
        optional: PropTypes.bool,
        showValidation: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        optional: false,
        showValidation: false,
    };

    render() {
        const { disabled, fieldNames, formName, optional, showValidation } = this.props;

        return (
            <>
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label="Email"
                    name={fieldNames[EMAIL]}
                    placeholder={optional ? 'Optional' : 'Required'}
                    showValidation={showValidation}
                    type="text"
                />
                <PhoneNumberInput
                    disabled={disabled}
                    fieldNames={fieldNames}
                    formName={formName}
                    placeholder={optional ? 'Optional' : 'Required'}
                    showValidation={showValidation}
                />
            </>
        );
    }
}
