import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, Box, Button, Dialog, Typography } from '@og-pro/ui';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';

import { RichTextInput } from '../../../RichTextInput';
import { generativeAI, saveReportAsAttachment } from '../../../../actions/addendums';
import { LoadingSpinner } from '../../../LoadingSpinner/LoadingSpinner';

export const AddendumAIDialog = ({ addendum, onSaveSuccess, onUpdateClick, toggleDialog }) => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const { data, error, isFetching, refetch } = useQuery({
        enabled: false,
        queryKey: ['AddendaGenerativeAI', projectId, addendum.id],
        queryFn: () => dispatch(generativeAI(projectId, addendum.id)),
        retry: false,
        refetchOnWindowFocus: false,
    });
    const { status, mutateAsync: saveReport } = useMutation({
        mutationKey: ['SaveReportAsAttachment', projectId, addendum.id],
        mutationFn: (d) => dispatch(saveReportAsAttachment(projectId, addendum.id, d)),
    });
    const [content, setContent] = useState('');

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (data?.content) {
            setContent(data.content);
        }
    }, [data]);

    const onSave = async () => {
        try {
            await saveReport({ html: content });
            onSaveSuccess();
        } catch (e) {} // eslint-disable-line
    };
    const isSaving = status === 'loading';

    return (
        <Dialog
            dialogTitle="Generate Addendum with AI"
            fullWidth
            maxWidth="lg"
            onClose={toggleDialog}
            open
        >
            <Box>
                {isFetching && (
                    <LoadingSpinner
                        text={[
                            'Please wait while we generate your content...',
                            'Just a couple of seconds',
                            'Hold on tight, we are almost there',
                            'This is taking a while...but we are still working',
                            'Almost there...',
                        ]}
                        useOpenGovStyle
                    />
                )}
                {(error || status === 'error') && (
                    <Box sx={{ my: 4 }}>
                        <Alert severity="error" variant="filled">
                            <AlertTitle>Something went wrong!</AlertTitle>
                            <Typography>
                                We had some problems generating the content. Try again and if the
                                problem persists please contact support.
                            </Typography>
                        </Alert>
                    </Box>
                )}
                {!error && data && !isFetching && (
                    <Box sx={{ my: 2 }}>
                        <RichTextInput
                            disabled={isSaving}
                            help="This is the suggested content generated by the AI. Review, make any necessary changes and when you are finished click one of the options below"
                            input={{
                                value: content,
                                onChange: (v) => {
                                    setContent(v);
                                },
                            }}
                            label="Suggested content"
                            meta={{}}
                            minRows={4}
                            name="addendum-ai-content"
                            showValidation
                            useOpenGovStyle
                        />
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                gap: 1,
                                justifyContent: 'flex-end',
                                mt: 2,
                            }}
                        >
                            <Button
                                color="secondary"
                                disabled={isSaving}
                                onClick={toggleDialog}
                                qaTag="addendum-generateWithAICancel"
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                disabled={isSaving || !content}
                                loading={isSaving}
                                onClick={() => onUpdateClick(content)}
                                qaTag="addendum-generateWithAISave"
                                variant="outlined"
                            >
                                Add content to Addendum description
                            </Button>
                            <Button
                                color="primary"
                                disabled={isSaving || !content}
                                loading={isSaving}
                                onClick={() => onSave()}
                                qaTag="addendum-generateWithAISave"
                                variant="contained"
                            >
                                Add content to Addendum as an attachment
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </Dialog>
    );
};

AddendumAIDialog.propTypes = {
    addendum: PropTypes.object.isRequired,
    onSaveSuccess: PropTypes.func.isRequired,
    onUpdateClick: PropTypes.func.isRequired,
    toggleDialog: PropTypes.func.isRequired,
};
