import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { hideVendorProfileModal, loadVendor } from '../../actions/vendorProfile';
import { Button } from '../Button';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { LoadingError } from '../LoadingError/LoadingError';
import { VendorProfile } from '../VendorProfile';
import { loadPublicVendor } from '../../actions/publicVendor';
import { getUserJS, isVendorAdminUser } from '../../containers/selectors';
import { getVendorJS } from './selectors';

export const VendorProfileModal = () => {
    const { governmentCode } = useParams();

    const dispatch = useDispatch();

    const user = useSelector(getUserJS);
    const isVendorAdmin = useSelector(isVendorAdminUser);
    const showModal = useSelector((state) => state.vendorProfile.get('showVendorModal'));
    const vendorId = useSelector((state) => state.vendorProfile.get('vendorId'));

    const loadError = useSelector((state) => state.publicVendor.get('loadVendorError'));
    const loading = useSelector(
        (state) =>
            state.publicVendor.get('loadingVendor') || state.vendorProfile.get('loadingVendor')
    );
    const vendor = useSelector((state) =>
        !isVendorAdmin ? state.publicVendor.get('vendor')?.toJS() : getVendorJS(state)
    );

    useEffect(() => {
        if (!vendorId || !showModal) return;

        if (!isVendorAdmin && governmentCode) {
            dispatch(loadPublicVendor(vendorId, governmentCode));
        }

        if (isVendorAdmin) {
            dispatch(loadVendor(vendorId));
        }
    }, [dispatch, vendorId, user?.government?.code, showModal]);

    return (
        <div>
            <Modal onHide={() => dispatch(hideVendorProfileModal())} show={showModal}>
                <Modal.Body>
                    {loading && <LoadingSpinner useOpenGovStyle />}
                    {loadError && <LoadingError error={loadError} />}
                    {vendor && <VendorProfile publicView={!isVendorAdmin} vendor={vendor} />}
                    <div className="text-center">
                        <Button
                            onClick={() => dispatch(hideVendorProfileModal())}
                            qaTag="connectedVendorProfileModal-done"
                        >
                            Done
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};
