import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { formValueSelector, getFormSyncErrors } from 'redux-form';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { attachmentTypesDict } from '@og-pro/shared-config/attachments';
import { ATTACHMENTS } from '@og-pro/shared-config/questionnaires';

import {
    BaseSectionsNavBackButton,
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavNextButton,
    SectionHeaderViewTitleOnly,
} from '../../../../../../components/SDv2';
import { ProjectCreateV2FunctionsContext } from '../../context';
import { ProjectAttachmentBlock } from '../../../../../../components';
import { onlyWordAndPdfFileTypesAccepted } from '../../../../constants';

const { EXTERNAL_DOCUMENT } = attachmentTypesDict;

export const ProjectCreateV2UploadDocument = () => {
    const { colors } = capitalDesignTokens.foundations;
    const styles = require('./index.scss');

    const navigate = useNavigate();
    const { buildRouteFunction, disabled, form, initiateSneakyUpdate, project, showFormErrors } =
        useContext(ProjectCreateV2FunctionsContext);
    const attachments = useSelector((state) => formValueSelector(form)(state, ATTACHMENTS));
    const externalDocument = attachments.find(
        (attachment) => attachment.type === EXTERNAL_DOCUMENT
    );
    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));

    //  eslint-disable-next-line no-underscore-dangle
    const error = formErrors?.externalDocument?._error;

    return (
        <Box className={styles.container}>
            <Box
                bgcolor="white"
                borderBottom={`1px solid ${colors.gray200}`}
                borderTop={`1px solid ${colors.gray200}`}
            >
                <SectionHeaderViewTitleOnly
                    description="This section is intended to upload an externally drafted document.
                    "
                    title="Upload Document"
                />
            </Box>
            <Box bgcolor="white" border={`1px solid ${colors.gray200}`} mx={7} my={3} p={3}>
                <Box>
                    <Typography fontWeight={500}>Upload and Manage Your Document</Typography>
                    <Typography color={colors.gray700} fontSize="default">
                        File type must be Word or PDF. Only one file can be added.
                    </Typography>
                    {showFormErrors && error && (
                        <Typography color={colors.red700} mt={1}>
                            {error}
                        </Typography>
                    )}
                    <Box mt={1.5}>
                        <ProjectAttachmentBlock
                            acceptedFileTypes={onlyWordAndPdfFileTypesAccepted}
                            attachmentListLabel="Uploaded External Document"
                            disabled={disabled}
                            emptyListSubtitle="Add an external document above to see it here"
                            emptyListTitle="No Documents"
                            form={form}
                            formKey={EXTERNAL_DOCUMENT}
                            hideAppendixLetter
                            hideDropzone={!!externalDocument}
                            hideNoAttachments
                            hideUpload
                            isOGThemeEnabledForComponents
                            label="Project External Document"
                            listAttachmentExcludeType={Object.values(attachmentTypesDict).filter(
                                (el) => el !== EXTERNAL_DOCUMENT
                            )}
                            listAttachmentType={EXTERNAL_DOCUMENT}
                            projectId={project.id}
                            triggerUpdate={initiateSneakyUpdate}
                            uploadAttachmentType={EXTERNAL_DOCUMENT}
                        />
                    </Box>
                </Box>
            </Box>
            <Box bgcolor="white" borderTop={`1px solid ${colors.gray200}`} py={2}>
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        <BaseSectionsNavBackButton
                            onClick={() => navigate(buildRouteFunction('project-properties'))}
                        >
                            Properties
                        </BaseSectionsNavBackButton>
                    }
                    lastSectionComponent={
                        <BaseSectionsNavNextButton
                            onClick={() => navigate(buildRouteFunction('attachments'))}
                        >
                            Exhibits
                        </BaseSectionsNavNextButton>
                    }
                    sections={[]}
                />
            </Box>
        </Box>
    );
};
