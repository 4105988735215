import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Badge } from 'react-bootstrap';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { navItemTypes } from './constants';
import { getAddendumsJS, getNavItems, getNoticesJS } from './selectors';
import connectData from '../ConnectData';
import { isSubscribedToProject } from '../selectors';
import { getProjectJS, hasAutoAddendaSubscription } from '../GovApp/selectors';
import { getProjectJS as getPublicProjectJS } from '../PublicApp/selectors';
import { loadAddendumProposals, loadAddendums } from '../../actions/addendums';
import { downloadAttachments } from '../../actions/publicProject';
import {
    AddendumsList,
    LoadingError,
    LoadingSpinner,
    Main,
    Nav,
    NavItem,
    SectionTitle,
    ZeroState,
} from '../../components';
import { GovernmentAddendums } from '../../components/connected';

const mapStateToProps = (state, props) => {
    return {
        addendums: getAddendumsJS(state),
        hasAutoAddenda: props.isGovernmentView ? hasAutoAddendaSubscription(state) : undefined,
        isSubscribed: isSubscribedToProject(state),
        loaded: state.addendums.get('loadedList'),
        loadError: state.addendums.get('loadListError'),
        loading: state.addendums.get('loadingList'),
        navItems: getNavItems(state, props),
        notices: getNoticesJS(state),
        project: props.projectSelector(state),
    };
};

const mapDispatchToProps = {
    loadAddendums,
    downloadAttachments,
};

// @connect
class ConnectedProjectAddendums extends Component {
    static propTypes = {
        addendums: PropTypes.array.isRequired,
        hasAutoAddenda: PropTypes.bool,
        isCompactView: PropTypes.bool,
        isGovernmentView: PropTypes.bool,
        isSubscribedToProject: PropTypes.bool,
        loadAddendums: PropTypes.func.isRequired,
        loaded: PropTypes.bool.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        navItems: PropTypes.array.isRequired,
        notices: PropTypes.array.isRequired,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        projectSelector: PropTypes.func.isRequired, // Needed by `mapStateToProps`
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedNavItem: props.navItems[0].type,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    componentDidMount() {
        const { isGovernmentView, loaded, loading, project } = this.props;

        // Load addendums if they haven't already been rendered from the server.
        // This is required because the project ID is not available when loading
        // data from the server on page refresh within a vendor's proposal.
        if (!loaded && !loading && project.id) {
            const query = isGovernmentView ? { view: 'government' } : undefined;
            return this.props.loadAddendums(project.id, query);
        }
    }

    renderAddendums() {
        const { isGovernmentView, loadError, loading, notices, ...props } = this.props;

        if (isGovernmentView) {
            return <GovernmentAddendums {...props} />;
        }

        if (props.addendums.length === 0) {
            return <ZeroState title="No Addenda Have Been Issued" />;
        }

        return <AddendumsList {...props} />;
    }

    renderNotices() {
        const { isGovernmentView, loadError, loading, notices, ...props } = this.props;

        if (isGovernmentView) {
            return <GovernmentAddendums {...props} addendums={notices} isNotice />;
        }

        if (notices.length === 0) {
            return <ZeroState title="No Notices Have Been Issued" />;
        }

        return <AddendumsList {...props} addendums={notices} isNotice />;
    }

    renderBody() {
        const { isCompactView, loadError, loading, navItems } = this.props;

        const { selectedNavItem } = this.state;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        return (
            <div className="row">
                <div className={isCompactView ? 'col-md-4 col-md-offset-4' : 'col-sm-4 col-md-3'}>
                    <div className={this.styles.addendaNav}>
                        <Nav bsStyle="pills" stacked>
                            {navItems.map(({ count, label, type }) => {
                                return (
                                    <NavItem
                                        className={classnames({ active: selectedNavItem === type })}
                                        key={type}
                                        onClick={() => this.setState({ selectedNavItem: type })}
                                        overrideIsActive={false}
                                        qaTag={`projectAddendums-${label}`}
                                    >
                                        {label}
                                        <Badge className="pull-right">{count}</Badge>
                                    </NavItem>
                                );
                            })}
                        </Nav>
                    </div>
                </div>
                <div className={isCompactView ? 'col-xs-12' : 'col-sm-8 col-md-9'}>
                    {selectedNavItem !== navItemTypes.NOTICE && this.renderAddendums()}
                    {selectedNavItem === navItemTypes.ALL && (
                        <div className={this.styles.divider} />
                    )}
                    {selectedNavItem !== navItemTypes.ADDENDUM && this.renderNotices()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <Main className={this.styles.container}>
                <SectionTitle
                    info="Addenda & Notices issued following the posting of the project"
                    title="Addenda & Official Notices"
                />
                {this.renderBody()}
            </Main>
        );
    }
}

export const ProjectAddendums = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProjectAddendums);

/**
 * NOTE: we can't use decorators below because they don't seem to play nice with not being after the
 * previously defined component, so we use the standard HOC style instead.
 */

function fetchGovernmentAddendums(getState, dispatch, location, params) {
    const projectId = Number.parseInt(params.projectId, 10);
    return Promise.all([
        dispatch(loadAddendums(projectId, { view: 'government' })),
        dispatch(loadAddendumProposals(projectId)),
    ]);
}

function fetchPublicAddendums(getState, dispatch, location, params) {
    // Project ID is not in the vendor proposal container's URL
    const projectId = params.projectId
        ? Number.parseInt(params.projectId, 10)
        : getState().publicProject.getIn(['project', 'id']);

    if (projectId) {
        return dispatch(loadAddendums(projectId));
    }
}

// Government addenda dashboard container
const ProjectAddendumsGovernment = (props) => {
    return <ProjectAddendums isGovernmentView projectSelector={getProjectJS} {...props} />;
};
ProjectAddendums.Government = connectData(fetchGovernmentAddendums)(ProjectAddendumsGovernment);

// Government public view container
const ProjectAddendumsGovernmentPublicView = (props) => {
    return <ProjectAddendums isCompactView projectSelector={getProjectJS} {...props} />;
};
ProjectAddendums.GovernmentPublicView = connectData(fetchPublicAddendums)(
    ProjectAddendumsGovernmentPublicView
);

// Public container
const ProjectAddendumsPublic = (props) => {
    return <ProjectAddendums projectSelector={getPublicProjectJS} {...props} />;
};
ProjectAddendums.Public = connectData(fetchPublicAddendums)(ProjectAddendumsPublic);
