import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';
import React from 'react';

import { LoadingSpinner } from '../../../../components';

export const AiImporterLoading = ({ filename }) => {
    return (
        <Box>
            <Box component={LoadingSpinner} noPadding sx={{ paddingBottom: 2 }} useOpenGovStyle />
            <Typography sx={{ textAlign: 'center' }} variant="h3">
                Using AI to read insurance into OpenGov...
            </Typography>
            <Typography sx={{ paddingTop: 1, textAlign: 'center' }}>
                Analyzing: {filename}
            </Typography>
            <Typography sx={{ paddingTop: 0.5, textAlign: 'center' }} variant="bodySmall">
                This may take a few seconds depending on the size of the template.
            </Typography>
        </Box>
    );
};

AiImporterLoading.propTypes = {
    filename: PropTypes.string.isRequired,
};
