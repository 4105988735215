import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NoSsr } from '@mui/material';

import { Box, DropdownMenu, MenuItem, ListItemButton, ListItemText } from '@og-pro/ui';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { isRequisitionCancellable, isRequisitionClosed } from '@og-pro/shared-config/requisitions';

import { qaTagPageName } from '../constants';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import {
    cancelRequisition,
    copyRequisition,
    deleteRequisition,
    downloadRequisitionAuditCSVFile,
} from '../../../../actions/requisitions';
import { getRequisitionJS } from '../../../../selectors/govApp';
import { getUserJS, isOpenGovAdmin } from '../../../selectors';

export const ActionButtons = ({ disabled }) => {
    const dispatch = useDispatch();
    const requisition = useSelector(getRequisitionJS);
    const { organization } = useSelector(getUserJS);
    // Special escape hatch delete of properly closed requests for internal employees
    const isEscapeHatchDeletable =
        useSelector(isOpenGovAdmin) && isRequisitionClosed(requisition.status);
    const isCancellable = isRequisitionCancellable(requisition.status);

    const [isExportingToCSV, setIsExportingToCSV] = useState(false);

    const copyHandler = () =>
        dispatch(
            showConfirmationSimpleModal(() => dispatch(copyRequisition(requisition.id)), {
                bsStyle: 'primary',
                btnText: 'Copy Request',
                icon: 'copy',
                text: 'Are you sure you want to create a copy this request?',
            })
        );

    const cancelHandler = () =>
        dispatch(
            showConfirmationSimpleModal(() => dispatch(cancelRequisition(requisition.id)), {
                btnText: 'Cancel Request',
                icon: 'ban',
                text: 'Are you sure you want to cancel this request?\nThis action cannot be undone.',
            })
        );

    const escapeHatchDeleteHandler = () =>
        dispatch(
            showConfirmationSimpleModal(() => dispatch(deleteRequisition(requisition.id)), {
                btnText: 'Delete Request',
                icon: 'delete',
                text: 'You must first check with the FIN Engineering Team to ensure any Purchase Order and/or Request records associated with this request have been removed before deleting this Closed Request.  Are you sure you still want to delete this request?  This action cannot be undone',
            })
        );

    const exportToCSVHandler = () => {
        setIsExportingToCSV(true);

        dispatch(
            downloadRequisitionAuditCSVFile(
                requisition.id,
                requisition.identifier,
                organization.timezone
            )
        ).finally(() => setIsExportingToCSV(false));
    };

    return (
        <NoSsr>
            <DropdownMenu
                color="secondary"
                disabled={disabled}
                endIcon={<ArrowDropDownIcon />}
                id={`${qaTagPageName}-more-button`}
                label="Actions"
                menuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }}
                pullRight
                qaTag={`${qaTagPageName}-moreActions`}
                variant="outlined"
            >
                <Box>
                    <MenuItem disablePadding>
                        <ListItemButton onClick={copyHandler} qaTag={`${qaTagPageName}-copy`}>
                            <ListItemText primary="Copy Request" />
                        </ListItemButton>
                    </MenuItem>
                    <MenuItem disablePadding>
                        <ListItemButton
                            disabled={isExportingToCSV}
                            onClick={exportToCSVHandler}
                            qaTag={`${qaTagPageName}-exportAudit`}
                        >
                            <ListItemText primary="Export Audit History to CSV" />
                        </ListItemButton>
                    </MenuItem>
                    {isCancellable && (
                        <MenuItem disablePadding>
                            <ListItemButton
                                onClick={cancelHandler}
                                qaTag={`${qaTagPageName}-cancel`}
                            >
                                <ListItemText primary="Cancel Request" />
                            </ListItemButton>
                        </MenuItem>
                    )}
                    {isEscapeHatchDeletable && (
                        <MenuItem disablePadding>
                            <ListItemButton
                                onClick={escapeHatchDeleteHandler}
                                qaTag={`${qaTagPageName}-delete`}
                            >
                                <ListItemText primary="Delete Request (OpenGov Staff Only)" />
                            </ListItemButton>
                        </MenuItem>
                    )}
                </Box>
            </DropdownMenu>
        </NoSsr>
    );
};

ActionButtons.propTypes = {
    disabled: PropTypes.bool,
};
