import { isURL } from 'validator';

import { projectTimelineConfigurations } from '@og-pro/shared-config/timelines';

import { REQUIRED_TEXT } from './constants';
import { hasFormErrors } from '../../helpers';
import { getProjectTimelinesArray } from '../../helpers/project';
import { website } from './website';

// Dynamic timeline validation
export function timeline(values, opts) {
    const errors = {};
    const { skipRequiredFields } = opts;

    if (!values.title) {
        errors.title = REQUIRED_TEXT;
    } else if (values.title.length > 250) {
        errors.title = 'Timeline name cannot be longer than 250 characters';
    }

    if (!values.date && !values.hasTextDate && !skipRequiredFields) {
        errors.date = REQUIRED_TEXT;
    }

    if (values.hasTextDate && !values.textDate) {
        errors.textDate = REQUIRED_TEXT;
    }
    if (values.textDate && values.textDate.length > 250) {
        errors.textDate = 'Text date cannot be longer than 250 characters';
    }

    if (values.showLocation && !values.location && !skipRequiredFields) {
        errors.location = 'Please specify a location';
    }
    if (values.location && values.location.length > 2000) {
        errors.location = 'Location cannot be longer than 2000 characters';
    }

    return {
        ...errors,
        ...website(values, { fieldName: 'agendaUrl', optional: true }),
    };
}

/**
 * Project timeline validation
 * @param {object} values Form values to validate
 * @param {object} [opts={}] Options
 * @param {boolean} [opts.requireChronologicalOrder] Requires timelines to be in chronological order
 * @param {boolean} [opts.skipRequiredFields] Skips the required timeline field check
 * @return {object} Error object formatted by the field names
 */
export function projectTimelines(values, opts = {}) {
    const errors = {
        timelineConfig: {},
        timelines: [],
    };

    // Some projects don't have timelines
    if (!values.timelineConfig) {
        return errors;
    }

    const { skipRequiredFields, requireChronologicalOrder } = opts;
    const timelineConfig = values.timelineConfig || {};

    projectTimelineConfigurations.forEach((config) => {
        const isFieldUsed = config.required || timelineConfig[config.isIncludedField];

        // Require value for date fields that are required or included (unless using skip option)
        if (isFieldUsed && !values[config.projectField] && !skipRequiredFields) {
            errors[config.projectField] = REQUIRED_TEXT;
        }

        // Require timeline title for date fields that are required or included
        if (isFieldUsed && !timelineConfig[config.titleField]) {
            const errorMessage = config.required
                ? `${REQUIRED_TEXT} You MUST enter a value!`
                : `${REQUIRED_TEXT} To remove this field, use the toggles below.`;
            errors.timelineConfig[config.titleField] = errorMessage;
        }

        // Check that timeline title field does not exceed limit
        if (timelineConfig[config.titleField] && timelineConfig[config.titleField].length > 128) {
            errors.timelineConfig[config.titleField] = 'Timeline name is too long';
        }

        // Require location if location required field is specified (unless using skip option)
        if (
            timelineConfig[config.locationRequiredField] &&
            !timelineConfig[config.locationField] &&
            !skipRequiredFields
        ) {
            errors.timelineConfig[config.locationField] = REQUIRED_TEXT;
        }

        // Check that location does not exceed limit
        if (
            timelineConfig[config.locationField] &&
            timelineConfig[config.locationField].length > 2000
        ) {
            errors.location = 'Location cannot be longer than 2000 characters';
        }

        // Check for valid URL for preProposal agenda
        if (
            config.preProposalAgendaUrlField &&
            isFieldUsed &&
            timelineConfig[config.preProposalAgendaUrlField]
        ) {
            const value = timelineConfig[config.preProposalAgendaUrlField];
            if (!isURL(value, { require_protocol: true })) {
                errors.timelineConfig[config.preProposalAgendaUrlField] =
                    'Must be a valid website url. Example: https://www.example.com';
            } else if (value.length > 256) {
                errors.timelineConfig[config.preProposalAgendaUrlField] = 'Field too long';
            }
        }
    });

    errors.timelines = (values.timelines || []).map((timelineValue) => {
        return timeline(timelineValue, opts);
    });

    if (hasFormErrors(errors)) {
        // Catchall ghost field just for displaying timeline error message in the UI since some
        // timeline errors can be hidden behind an edit button.
        errors.timelineHasErrors =
            'Timeline has form errors. Please use the "Edit Timeline Configuration" button to update the timeline.';
    }

    // Validation for chronological ordering of timeline dates
    if (requireChronologicalOrder) {
        let datesAreChronological = true;
        const timelineData = values.timelineData || getProjectTimelinesArray(values);

        timelineData
            .filter((t) => !!t.date)
            .forEach((timelineEntry, index) => {
                if (index === 0) {
                    return;
                }

                const currentEntry = timelineEntry;
                const previousEntry = timelineData[index - 1];

                const currentDate = new Date(currentEntry.date.valueOf());
                const previousDate = new Date(previousEntry.date.valueOf());

                // shift times to 00:00 if a time isn't specified per PRO-951
                if (!currentEntry.displayTime) {
                    currentDate.setHours(0, 0, 0, 0);
                }

                if (!previousEntry.displayTime) {
                    previousDate.setHours(0, 0, 0, 0);
                }

                if (currentDate < previousDate) {
                    datesAreChronological = false;
                }
            });

        if (!datesAreChronological) {
            errors.timelineHasErrors = 'Selected dates are not chronological.';
            errors.timelineChronologicError = true;
        }
    }

    if (timelineConfig.hasAuctionExtension && !skipRequiredFields) {
        const { auctionExtensionGracePeriod, auctionExtensionTime } = timelineConfig;

        if (!auctionExtensionGracePeriod || Number.parseFloat(auctionExtensionGracePeriod) <= 0) {
            errors.timelineConfig.auctionExtensionGracePeriod =
                'This field is required. Must be greater than 0.';
        }

        if (!auctionExtensionTime || Number.parseFloat(auctionExtensionTime) <= 0) {
            errors.timelineConfig.auctionExtensionTime =
                'This field is required. Must be greater than 0.';
        }
    }

    return errors;
}
