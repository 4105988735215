import React from 'react';

import { Box, Typography } from '@og-pro/ui';
import Icon from '@mdi/react';
import { mdiFileDocumentPlusOutline } from '@mdi/js';

export const EmptyInsuranceTable = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 4,
            }}
        >
            <Box>
                <Icon path={mdiFileDocumentPlusOutline} size={2} />
            </Box>
            <Typography sx={{ mt: 1 }} variant="h3">
                Add Insurance Documents
            </Typography>
            <Typography sx={{ mt: 1 }}>
                No insurance items have been added to this contract
            </Typography>
        </Box>
    );
};
