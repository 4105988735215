import { projectTypesDict } from '@og-pro/shared-config/projects';

export const PROCESS_INFORMATION_SECTIONS_TITLES = (eSignExternalAgreementEnabled) => [
    {
        title: 'General Properties',
        description: 'Design your template.',
        errorsKey: 'generalProperties',
    },
    {
        title: 'Summary & Background',
        description: 'This section is entirely optional at this stage.',
        // don't show this when the template is of type contract, or when it is special (except when its intake)
        shouldHide: (templateProject) =>
            templateProject.type === projectTypesDict.CONTRACT ||
            (templateProject.template.isSpecial && !templateProject.isIntake),
    },
    {
        title: 'Timeline',
        description: 'Define the standard timeline & milestones for projects using this template.',
        errorsKey: ['timelineConfig', 'timelines'],
        // don't show this when the template is of type contract, when its intake or when its special
        shouldHide: (templateProject) =>
            templateProject.type === projectTypesDict.CONTRACT ||
            templateProject.isIntake ||
            templateProject.template.isSpecial,
    },
    ...(eSignExternalAgreementEnabled
        ? [
              {
                  title: 'Document Builder Settings',
                  description: 'Determine how the document should be created.',
                  errorsKey: 'documentBuilderSettings',
                  shouldHide: (templateProject) =>
                      templateProject.type !== projectTypesDict.CONTRACT,
              },
          ]
        : []),
    {
        title: 'Document Export Settings',
        description:
            'Determine if documents should be generated automatically and choose the Word template.',
        errorsKey: 'documentExportSettings',
        // don't show if the template is special
        shouldHide: (templateProject) => templateProject.template.isSpecial,
    },
    {
        title: 'Sealed Bid Settings',
        description:
            'Sealed bid settings set here will be used for projects created from this template. These will be unchangeable on the project for everyone except the template admins.',
        errorsKey: 'sealedBidSettings',
        // don't show when its of type contract, when its intake or when its special
        shouldHide: (templateProject) =>
            templateProject.type === projectTypesDict.CONTRACT ||
            templateProject.isIntake ||
            templateProject.template.isSpecial,
    },
];

export const QUESTIONS_AUTOMATION_SECTION_TITLES = [
    {
        title: 'Document Set Up Questions',
        description:
            'Help walk project editors through the creation of a document by adding questions for them to answer. Create reusable variables or add automations based on answers to specific questions.',
    },
    {
        title: 'View All Automations',
        description: 'View and manage automations set up for this template.',
    },
];

export const ATTACHMENTS_TITLES = [
    {
        title: 'Attachments',
        description: 'Manage all documents that should be included with this project.',
    },
];
export const EXHIBITS_TITLES = [
    {
        title: 'Exhibits',
        description:
            'Upload and manage all documents that should be included in this contract packet.',
    },
];

export const getSignaturesTitles = (contractPackageCompilerEnabled) => [
    {
        title: 'Signatures',
        description: `Create a signature page by adding signatories. They will auto-format within your 
            contract ${contractPackageCompilerEnabled ? 'packet' : 'document'}. This step is optional.`,
    },
];

export const ERRORS = {
    COPY: { title: 'Unable to Copy this Template.', description: 'Please try again later.' },
    DELETE: { title: 'Unable to Delete this Template.', description: 'Please try again later.' },
    UPDATE: { title: 'Unable to Update this Template.', description: 'Please try again later.' },
    LIVE: {
        title: 'Unable to Set Template Live.',
        description: 'Please fix errors in {{LINKS}} before setting live.',
    },
    VALIDATION: {
        title: 'Unable to Save Template.',
        description: 'Please fix errors in {{LINKS}} before saving.',
    },
};
