import { createSelector } from 'reselect';

const getChartConfig = (state) => state.dashboardAiChart.get('chartConfig');

export const getAiChartConfig = createSelector([getChartConfig], (chartConfig) => {
    if (chartConfig) {
        return chartConfig.toJS();
    }

    return {};
});
