import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Box, Button, CircularProgress, NestedDropdownMenu } from '@og-pro/ui';
import { projectDeliveryMethods, projectTypesDict } from '@og-pro/shared-config/projects';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { isLoadingExport } from '../selectors';
import { getProjectJS } from '../../selectors';
import { getWritingSections } from '../../ProjectCreate/selectors';
import {
    compileDocument,
    configureExport,
    exportProject,
    exportProjectDocument,
    showExportModal,
} from '../../../../actions/exportProject';
import { trackEvent } from '../../../../helpers';
import { exportButtonIds } from './constants';
import {
    initiateExport,
    initiateExportDocument,
} from '../../../../actions/project/create/projectCreate';

const {
    PREVIEW_MAIN_DOCUMENT_DOCX,
    PREVIEW_MAIN_DOCUMENT_PDF,
    PREVIEW_CURRENT_SECTION_DOCX,
    PREVIEW_CURRENT_SECTION_PDF,
    PREVIEW_CONTRACT_PACKAGE_PDF,
    DOWNLOAD_MAIN_DOCUMENT_DOCX,
    DOWNLOAD_MAIN_DOCUMENT_PDF,
    DOWNLOAD_CURRENT_SECTION_DOCX,
    DOWNLOAD_CURRENT_SECTION_PDF,
    DOWNLOAD_CONTRACT_PACKAGE_PDF,
    FULL_PACKAGE_WITH_ATTACHMENTS,
    PREVIEW_MAIN_DOCUMENT,
    DOWNLOAD_MAIN_DOCUMENT,
} = exportButtonIds;

export const ExportButtonV2 = ({ context, disabled, shouldSave, standalone }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [query] = useSearchParams();
    const [selectedSection, setSelectedSection] = useState({});
    const loading = useSelector(isLoadingExport);
    const project = useSelector(getProjectJS);
    const writingSections = useSelector(getWritingSections);
    const isDocument = location.pathname.includes('document');
    const selectedSectionIdOrIdx = useRef(null);

    useEffect(() => {
        if (!isDocument) {
            selectedSectionIdOrIdx.current = null;
            return setSelectedSection({});
        }

        const activeSectionId = query.get('section');
        const activeSectionIdx = query.get('activeSection') || 0; // Accounts for case when first navigating to document

        if (
            selectedSectionIdOrIdx.current !== +activeSectionId ||
            selectedSectionIdOrIdx.current !== +activeSectionIdx
        ) {
            if (activeSectionId) {
                setSelectedSection(
                    project.projectSections.find((section) => section.id === +activeSectionId)
                );
                selectedSectionIdOrIdx.current = +activeSectionId;
            } else {
                const filteredSections = writingSections.filter(
                    (section) => section.id !== 1 && section.id !== 2 && section.id !== 3
                );
                setSelectedSection(filteredSections[+activeSectionIdx]);
                selectedSectionIdOrIdx.current = +activeSectionIdx;
            }
        }
    }, [isDocument, project.projectSections, query, writingSections]);

    const handleDownloadClick = (sectionOnly = false, isPdf = false) => {
        const eventData = { view: context };

        if (sectionOnly) {
            trackEvent('Export Project Section Modal', eventData);
            dispatch(configureExport({ isPdf, downloadImmediately: true }, selectedSection.id));
        } else {
            trackEvent('Export Project Modal', eventData);
            dispatch(configureExport({ isPdf, downloadImmediately: true }));
        }

        if (shouldSave) {
            dispatch(exportProjectDocument());
        } else {
            dispatch(initiateExportDocument());
        }
    };

    const handleDownloadFullPackageClick = () => {
        const eventData = { view: context };

        dispatch(configureExport({ downloadImmediately: true }));

        if (shouldSave) {
            trackEvent('Export Project Section Modal', eventData);
            dispatch(initiateExport());
        } else {
            trackEvent('Export Project Modal', eventData);
            dispatch(exportProject());
        }
    };

    const handlePreviewClick = (isCompilePreview, sectionOnly, isPdf) => {
        const eventData = { view: context };

        dispatch(configureExport({ isPdf }));

        if (sectionOnly) {
            trackEvent('Preview Project Section', eventData);
            dispatch(showExportModal('preview', shouldSave, selectedSection.id));
        } else {
            trackEvent('Preview Project', eventData);
            dispatch(showExportModal(isCompilePreview ? 'compilePreview' : 'preview', shouldSave));
        }
    };

    const handleClickOption = (option) => {
        switch (option.id) {
            case PREVIEW_MAIN_DOCUMENT_DOCX:
                handlePreviewClick(false, false, false);
                break;
            case PREVIEW_MAIN_DOCUMENT_PDF:
                handlePreviewClick(false, false, true);
                break;
            case PREVIEW_CURRENT_SECTION_DOCX:
                handlePreviewClick(false, true, false);
                break;
            case PREVIEW_CURRENT_SECTION_PDF:
                handlePreviewClick(false, true, true);
                break;
            case PREVIEW_CONTRACT_PACKAGE_PDF:
                handlePreviewClick(true, false, true);
                break;
            case DOWNLOAD_MAIN_DOCUMENT_DOCX:
                handleDownloadClick(false, false);
                break;
            case DOWNLOAD_MAIN_DOCUMENT_PDF:
                handleDownloadClick(false, true);
                break;
            case DOWNLOAD_CURRENT_SECTION_DOCX:
                handleDownloadClick(true, false);
                break;
            case DOWNLOAD_CURRENT_SECTION_PDF:
                handleDownloadClick(true, true);
                break;
            case FULL_PACKAGE_WITH_ATTACHMENTS:
                handleDownloadFullPackageClick();
                break;
            case DOWNLOAD_CONTRACT_PACKAGE_PDF:
                dispatch(compileDocument(project.id, true, projectDeliveryMethods.URL));
                break;
            default:
                break;
        }
    };

    const options = [
        {
            value: 'Preview',
            menuLevel: 0,
            type: 'sectionName',
        },
        ...(!project.useExternalDocument
            ? [
                  {
                      id: PREVIEW_MAIN_DOCUMENT,
                      value: 'Full Document',
                      menuLevel: 0,
                      type: 'menuItem',
                      nestedOptions: [
                          {
                              value: 'DOCX',
                              menuLevel: 1,
                              type: 'menuItem',
                              id: PREVIEW_MAIN_DOCUMENT_DOCX,
                          },
                          {
                              value: 'PDF',
                              menuLevel: 1,
                              type: 'menuItem',
                              id: PREVIEW_MAIN_DOCUMENT_PDF,
                          },
                      ],
                  },
              ]
            : [{}]),
        ...(selectedSection?.id
            ? [
                  {
                      value: 'Current Section',
                      menuLevel: 0,
                      type: 'menuItem',
                      nestedOptions: [
                          {
                              value: 'DOCX',
                              menuLevel: 1,
                              type: 'menuItem',
                              id: PREVIEW_CURRENT_SECTION_DOCX,
                          },
                          {
                              value: 'PDF',
                              menuLevel: 1,
                              type: 'menuItem',
                              id: PREVIEW_CURRENT_SECTION_PDF,
                          },
                      ],
                  },
              ]
            : []),
        ...(project.type === projectTypesDict.CONTRACT
            ? [
                  {
                      value: 'Contract Packet - PDF',
                      menuLevel: 0,
                      type: 'menuItem',
                      id: PREVIEW_CONTRACT_PACKAGE_PDF,
                  },
              ]
            : []),
        {
            menuLevel: 0,
            type: 'divider',
        },
        {
            value: 'Download',
            menuLevel: 0,
            type: 'sectionName',
        },
        ...(!project.useExternalDocument
            ? [
                  {
                      id: DOWNLOAD_MAIN_DOCUMENT,
                      value: 'Full Document',
                      menuLevel: 0,
                      type: 'menuItem',
                      nestedOptions: [
                          {
                              value: 'DOCX',
                              menuLevel: 1,
                              type: 'menuItem',
                              id: DOWNLOAD_MAIN_DOCUMENT_DOCX,
                          },
                          {
                              value: 'PDF',
                              menuLevel: 1,
                              type: 'menuItem',
                              id: DOWNLOAD_MAIN_DOCUMENT_PDF,
                          },
                      ],
                  },
              ]
            : [{}]),
        ...(selectedSection?.id
            ? [
                  {
                      value: 'Current Section',
                      menuLevel: 0,
                      type: 'menuItem',
                      nestedOptions: [
                          {
                              value: 'DOCX',
                              menuLevel: 1,
                              type: 'menuItem',
                              id: DOWNLOAD_CURRENT_SECTION_DOCX,
                          },
                          {
                              value: 'PDF',
                              menuLevel: 1,
                              type: 'menuItem',
                              id: DOWNLOAD_CURRENT_SECTION_PDF,
                          },
                      ],
                  },
              ]
            : []),
        ...(project.type === projectTypesDict.CONTRACT
            ? [
                  {
                      value: 'Contract Packet - PDF',
                      menuLevel: 0,
                      type: 'menuItem',
                      id: DOWNLOAD_CONTRACT_PACKAGE_PDF,
                  },
              ]
            : []),
        ...(project.type !== projectTypesDict.CONTRACT
            ? [
                  {
                      value: 'Full Packet With Attachments',
                      menuLevel: 0,
                      type: 'menuItem',
                      id: FULL_PACKAGE_WITH_ATTACHMENTS,
                  },
              ]
            : []),
    ];

    return (
        <NestedDropdownMenu
            buttonComponent={
                standalone ? undefined : (
                    <Button
                        color="secondary"
                        size="large"
                        sx={{
                            height: '40px',
                            minHeight: '40px',
                            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
                            fontSize: '14px',
                            fontWeight: '400',
                            transition: 'none',
                            border: 'none',
                            '&:active': {
                                border: 'none',
                            },
                            '&:focus': {
                                border: 'none',
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                                border: 'none',
                                color: 'black',
                            },
                        }}
                    />
                )
            }
            disabled={disabled || loading}
            label={
                <Box alignItems="center" display="flex" gap={0.5}>
                    {standalone ? null : <CloudDownloadIcon fontSize="small" />}
                    Export
                    {loading ? (
                        <Box alignItems="center" display="flex" justifyContent="center" width={20}>
                            <CircularProgress color="inherit" size="extraSmall" />
                        </Box>
                    ) : (
                        <ArrowDropDownIcon fontSize="small" />
                    )}
                </Box>
            }
            menuLevels={2}
            menuPlacement="bottom-end"
            onOptionClick={handleClickOption}
            options={options}
            qaTag="export-document-button"
        />
    );
};

ExportButtonV2.propTypes = {
    context: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    shouldSave: PropTypes.bool,
    standalone: PropTypes.bool,
};
