import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import {
    COMMENT,
    CUSTOM_1,
    CUSTOM_2,
    CUSTOM_3,
    CUSTOM_4,
    CUSTOM_5,
    DESCRIPTION,
    DISCOUNT,
    LINE_ITEM,
    NO_BID,
    QUANTITY,
    UNIT_PRICE,
    UNIT_TO_MEASURE,
} from '@og-pro/shared-config/priceTables';
import { calculatePriceItemTotalCost } from '@og-pro/shared-config/priceTables/utils';

import { ProposalSectionTitle } from '../ProposalSectionTitle';
import { PriceTable } from '../../../PriceTable';
import { PriceTableDocx } from '../../../PriceTableDocx';
import { calculatePriceTableTotals, getPriceItemValue } from '../../../../helpers';

export class PriceTablesSection extends PureComponent {
    static propTypes = {
        auctionMaxFractionDigits: PropTypes.number,
        exportFileName: PropTypes.string,
        isDocx: PropTypes.bool,
        isPricingSealed: PropTypes.bool,
        isPrinterView: PropTypes.bool,
        isReverseAuction: PropTypes.bool,
        printButton: PropTypes.node,
        proposal: PropTypes.shape({
            priceTables: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                })
            ),
        }).isRequired,
        salesTax: PropTypes.number,
    };

    static defaultProps = {
        printButton: undefined,
    };

    get styles() {
        return require('../../index.scss');
    }

    renderDocxPriceTables() {
        const {
            auctionMaxFractionDigits,
            isReverseAuction,
            proposal: { priceTables },
            salesTax,
        } = this.props;

        const priceTableDisplays = priceTables.map((priceTable) => {
            const { salesTaxCost, totalCost } = calculatePriceTableTotals(priceTable, salesTax);

            const priceTableData = {
                ...priceTable,
                priceItems: priceTable.priceItems.map((priceItem) => {
                    if (priceItem.isHeaderRow) {
                        return priceItem;
                    }

                    return {
                        ...priceItem,
                        comment: getPriceItemValue(COMMENT, priceItem),
                        custom1: getPriceItemValue(CUSTOM_1, priceItem),
                        custom2: getPriceItemValue(CUSTOM_2, priceItem),
                        custom3: getPriceItemValue(CUSTOM_3, priceItem),
                        custom4: getPriceItemValue(CUSTOM_4, priceItem),
                        custom5: getPriceItemValue(CUSTOM_5, priceItem),
                        description: getPriceItemValue(DESCRIPTION, priceItem),
                        discount: getPriceItemValue(DISCOUNT, priceItem),
                        lineItem: getPriceItemValue(LINE_ITEM, priceItem),
                        noBid: getPriceItemValue(NO_BID, priceItem),
                        quantity: getPriceItemValue(QUANTITY, priceItem),
                        totalCost: calculatePriceItemTotalCost(
                            priceItem,
                            priceTable.percentageAdjustmentType
                        ),
                        unitPrice: getPriceItemValue(UNIT_PRICE, priceItem),
                        unitToMeasure: getPriceItemValue(UNIT_TO_MEASURE, priceItem),
                    };
                }),
                salesTaxCost,
                totalCost,
            };

            return (
                <PriceTableDocx
                    auctionMaxFractionDigits={auctionMaxFractionDigits}
                    includeResponse
                    isReverseAuction={isReverseAuction}
                    key={priceTable.id}
                    priceTable={priceTableData}
                    salesTax={salesTax}
                    size="xl"
                />
            );
        });

        return (
            <div className={this.styles.section}>
                <ProposalSectionTitle isDocx title="PRICE TABLES" />
                <div className={this.styles.sectionItem}>{priceTableDisplays}</div>
            </div>
        );
    }

    render() {
        const {
            auctionMaxFractionDigits,
            exportFileName,
            isDocx,
            isPricingSealed,
            isPrinterView,
            isReverseAuction,
            printButton,
            proposal: { priceTables },
            salesTax,
        } = this.props;

        if (priceTables.length === 0) {
            return null;
        }

        if (isPricingSealed) {
            return (
                <div className={this.styles.section}>
                    <ProposalSectionTitle
                        isDocx={isDocx}
                        title={
                            <>
                                PRICE TABLES&nbsp;&nbsp;
                                <i className="fa fa-lock" />
                            </>
                        }
                    />
                    <div className={this.styles.sectionItem}>
                        <i className="fa fa-lock" />
                        &nbsp;
                        <em>Pricing information has not been unsealed yet</em>
                    </div>
                </div>
            );
        }

        if (isDocx) {
            return this.renderDocxPriceTables();
        }

        const priceTableDisplays = priceTables.map((priceTable) => {
            // NOTE: `isPrinterView` is also used to update the key to re-render the price table
            return (
                <PriceTable
                    auctionMaxFractionDigits={auctionMaxFractionDigits}
                    exportFileName={exportFileName}
                    isPrinterView={isPrinterView}
                    isReverseAuction={isReverseAuction}
                    key={`${priceTable.id}${isPrinterView}`}
                    priceTable={priceTable}
                    readOnly
                    salesTax={salesTax}
                />
            );
        });

        return (
            <div className={this.styles.section}>
                <ProposalSectionTitle
                    isDocx={isDocx}
                    printButton={printButton}
                    title="PRICE TABLES"
                />
                <div className={this.styles.sectionItem}>{priceTableDisplays}</div>
            </div>
        );
    }
}
