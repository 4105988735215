import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { ProjectListFiltersForm } from '../Forms/ProjectListFiltersForm';
import { formatFilterFormData } from '../../helpers';

const BaseProjectListFilters = (props) => {
    const {
        filterId,
        filterSubmitHandler,
        initialFilterFormValues,
        isDocBuilder,
        isIntake,
        location,
        storeLastFilter,
    } = props;

    const handleSubmit = useCallback(
        (data, isNewQuery) => {
            filterSubmitHandler(formatFilterFormData(data), isNewQuery);
        },
        [filterSubmitHandler, formatFilterFormData]
    );

    return (
        <ProjectListFiltersForm
            enableReinitialize
            initialSavedFilterId={filterId}
            initialValues={initialFilterFormValues}
            isDocBuilder={isDocBuilder}
            isIntake={isIntake}
            location={location}
            storeLastFilter={storeLastFilter}
            submitHandler={handleSubmit}
        />
    );
};

BaseProjectListFilters.propTypes = {
    filterId: PropTypes.number,
    filterSubmitHandler: PropTypes.func.isRequired,
    initialFilterFormValues: PropTypes.object,
    isDocBuilder: PropTypes.bool,
    isIntake: PropTypes.bool,
    location: PropTypes.object.isRequired,
    storeLastFilter: PropTypes.func.isRequired,
};

const MemoizedProjectListFilters = React.memo(BaseProjectListFilters);

export const ProjectListFilters = withRouter(MemoizedProjectListFilters);
